import { Fragment, useState,useCallBack } from 'react'
import {productStats} from '../Network'
import toast, { Toaster } from 'react-hot-toast';
function CardV2(props) {
    var product = props.item
    const  [color,setSelectedColor] = useState(null)
    const [isAdded, setAdded] = useState(props.existing)

    var unAvailable = 'object-cover w-full -mt-3 h-96 opacity-100 hover:opacity-50 ease-in-out duration-300 opacity-0'
    var  available = 'object-cover rounded-lg lg:object-cover md:object-cover  w-full -mt-3 h-60  lg:h-96 opacity-100 hover:opacity-50 ease-in-out duration-300'

    const validateItem = (e)=>{
        
        try {
            let existingCart = localStorage.getItem('UserCart')
            var cart = JSON.parse(existingCart)
            const found = cart.find(obj => {
                return obj._id === e._id;
              });
              
              let status =  found === undefined ? true :false
              return status
        } catch (error) {
            return  true
        }
    }

    async function recordProductStats(e,type) {
      try {
          const data = {storeOwner:"PaculbaFlowerShop",cType:"cartBehavior",cName:"website","data":e,"date":new Date(),action:type}
          const response = await productStats.put('/Items', data) 
          return  response
          
      } catch (error) {
      }
    }

    function addToCart(e){
      
        if (validateItem(e) && e.status ) {
            var variantItem = e
            variantItem.selectedColor = color
            recordProductStats(e,'Added')
            props.didAdd(variantItem)
            setAdded(true)
        }else {
          setAdded(false)
        }
      }


      function removeToCart(e){
        props.didRemove(e)
        setAdded(false)
      }

 
      let card2 =()=>{
        let productDetails = `/Details/${product._id}` 
    return (
        <div className='mb-10'>
       
    {product.status ? null: <div class="flex justify-center relative">
          <strong class="relative rounded-full h-6 px-4 text-xs font-extralight leading-6 text-white uppercase bg-red-900"> Out of stock </strong>
        </div> } 
        
      <section className={available + ` hover:shadow-lg`}> 
      {/* <a href="javascript:void(0);" */}
      <a href={productDetails}
      target="_blank" 
          // onClick={()=> addToCart(product)}
      class=" group grid place-items-center ">
  <img
          alt={product.title}
          src={product.imgUrl}
          class={available}
          style={{
            opacity: product.status ? 1 : 0.1
          }}
       />
        <p className='text-xs text-white opacity-50 mt-20 lg:mt-1' style={{position:'relative',top:-250,right:0}}>www.paculbaflowershop.com</p>
        <img/>
        <img
          
          src={"https://localflowershop.sgp1.digitaloceanspaces.com/product/1654912621316-paculba-new-icon.png"}
          
          class={"object-cover rounded-lg lg:object-cover md:object-cover  w-16 h-16  lg:-mt-72 md:-mt-72 -mt-72   content-start lg:h-20 lg:w-20  lg:opacity-80 opacity-60  hover:opacity-50 ease-in-out duration-300"}
          
        />
        {product.type === "VALENTINESDAY" ?  <img
          
          src={"https://localflowershop.sgp1.digitaloceanspaces.com/product/1707054944421-download%20%286%29.png"}
          
          class={"object-cover rounded-md lg:object-cover md:object-cover  w-14 h-14 lg:w-24 lg:h-24 ml-44 lg:-mt-40 md:-mt-40 -mt-40   content-start  opacity-100 hover:opacity-50 ease-in-out duration-300"}
          
        />: null}
          
        </a>
        
        
      </section>
        <h5 class="mt-4 text-sm mb-2 font-bold text-black/90"> {product.title} </h5>
        {/* <textarea
        disabled={true}
      class="
        form-control
        block
        w-full
        px-3
        py-1.5
        
        text-sm
        font-normal
        text-gray-500
        bg-white bg-clip-padding
        border border-solid border-gray-100
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
      id="exampleFormControlTextarea1"
      rows="2"
      placeholder="Your message"
      value={product.subtitle}
    ></textarea> */}
            <div class="flex items-center justify-between mt-4 font-bold">
           
              <p class="text-lg group-hover:text-black ease-in-out duration-300 "> 
              { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(product.price)}
              </p>
              <div className='flex  grid-cols-2'>
                <a href="javascript:void(0);" 
                   onClick={()=> addToCart(product)}
                class="block group"> 
              {product.status ?  <img
              width={20}
              height={20}
              onClick={()=> addToCart(product)}
              className="hover:opacity-20 ease-in-out duration-300 mr-4"
              alt={product.title}
              src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRymu6sl4j8YwAhAo8Rz0Owww4-jgw4gmmgQahRi5tyya1xVxAglgaUxpBXlBiEMRNFQM&usqp=CAU'}
            /> : null}
             </a> 
              </div>
            </div>
      
        </div>
        
    );
}
return card2()
}

export default CardV2;