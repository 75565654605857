import React, { useEffect, useState,useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import DD from './Sample'
import Form from './Form'
import Tran from './Tran'
import Modal from './Modal'
import Side from './SideSheet'
import Table from './Table'
import Footer from './component/Footer'
import DropD from './component/Dropdown'
import CartView from './component/CartView'
import {DropDownButton} from './component/dropdownv2'
import PopupProduct from './component/PopupProduct'
import MegaMenu from './component/Mega'
import ViewProduct from './component/ViewProduct'
import CategoryView from './component/CategoryItem'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Card, CardPrice, Toggle, PlaceholderCard, SideCart, LandingHeader, CategoryPH } from './component'
import { Dialog } from '@headlessui/react'
import { axios,productStats } from './Network'
import FixedDatePicker from './component/FixedDatePicker'
import { useMemo } from 'react';
import CardV2 from './component/CardV2'
import toast, { Toaster } from 'react-hot-toast';
import ContentLoader from "react-content-loader"
import moment, { normalizeUnits } from 'moment'
import momentTZ  from 'moment-timezone'
import { compareAsc, format,setHours ,setMinutes} from 'date-fns'
import { fi } from 'date-fns/locale';
import ReactGA from 'react-ga';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

function DatePickerComponent({date,onChangeDate,navigator,store}) {
  const [isValid, setValid] = useState(false)
  const bookedStyle = { border: '2px solid currentColor',backgroundColor:'#0652DD',color:'white' };
  let displayError = false

  async function recordProductStats(e) {
    try {
        const data = {storeOwner:"PaculbaFlowerShop",cType:"calendarBehavior",cName:"website","userPreferredDate":moment(e).format("MMMM DD YYYY").toString() ,"date":new Date()}
        const response = await productStats.put('/items', data) 
        return  response
    } catch (error) {
    }
  }

  const validateDate =(e)=>{
    
    if (moment(e).isBefore(new Date())){
      toast('Please try again later..')
      setValid(true)
      displayError = true
    }else {
      recordProductStats(e)
      setValid(false)
      displayError = false
      onChangeDate(e)
    }
    
  }
  const filterUnavailableDates=()=>{
    try {
      return store.storeOptions.businessHours.unAvailableDates.map( item=>{
         return moment(item).toDate()
      })
    }catch{
      return []
    }
    
  }
  const displayFooter = ()=>{
     
    let invalidContent =  <p className="text-xs animate-pulse text-red-500">{ 'Please select future date'}</p>
    let validContent =  <p className="text-xs">{''}</p>
    return  !isValid ? null : invalidContent
  }
  return <DayPicker
  mode="single"
  value={date}
  defaultMonth={new Date()}
  fromMonth={new Date()}
  // toDate={}
  modifiers={{ booked: [date] }}
  modifiersStyles={{ booked: bookedStyle }}
  disabled={filterUnavailableDates() }
  today={new Date()}
  styles={{
    // head: 'custom-head',
    caption: { color: '#0652DD' }
  }}
  footer={
    displayFooter()

  }
  onDayClick={(e)=> validateDate(e)}
  min={new Date()}
  required
   />;
}
ReactGA.initialize(process.env.REACT_APP_STORE_REACT_GA, {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});
var timeInManila = momentTZ.tz('Asia/Manila');
const MyLoader = (props) => (
  <ContentLoader viewBox="0 0 400 31" height={200} width={'auto'} {...props}>
    <rect height="5.5" rx="1" ry="1" width="340" x="31" y="5" />
    <rect height="5.5" rx="1" ry="1" width="340" x="31" y="15" />
    <circle cx="388" cy="12" r="12" />
    <rect height="24" rx="0" ry="0" width="24" x="0" y="0" />
  </ContentLoader>
)


var id =   process.env.REACT_APP_STORE_ID
var primaryProduct =   process.env.REACT_APP_DEFAULT_PRIMARY_PRODUCT
var secondProduct =   process.env.REACT_APP_DEFAULT_SECOND_PRODUCT

// [Flux-Bouquet,Lux-Arrangements]
function App() {
  const storeRef = useRef(null)

  const [isActiveSearch, setActiveSearch] = useState(false)
  const [isDisplaySearched, setDisplaySearched] = useState(false)
  const [searchedResults, setSearchResults] = useState([])
  const [searchedItem, setSearchedItem] = useState(null)
  const [status, setStatus] = useState(false)
  const [suggestStatus, setSuggestStatus] = useState(false)
  const [cart, setCartItems] = useState([])
  const [showCart, setShowCart] = useState(false)
  let [isOpen, setIsOpen] = useState(true)
  const [scroll, setScroll] = useState(0)
  const [didUpdate, setUpdate] = useState(true)
  const [product, setProducts] = useState([])
  const [addons, setAddonProduct] = useState([])
  const [suggestedProduct, setSuggestedProduct] = useState([])
  const [topItems, setTopItems] = useState([])
  const [category, setSelectedCategory] = useState(primaryProduct) //
  const [categories, setCategory] = useState([])
  const [storeDetails, setStoreInfo] = useState(null)
  const [hasVoucher, setHasVoucher] = useState(false)
  const [featuredProducts, setFeaturedProducts] = useState([])
  
  const [viewProduct, setCurrentView] = useState(null)
  const [selectedDate, setSelectedDate] = useState({date:new Date()})
  const listInnerRef = useRef();
  const [offset, setOffset] = useState(0);
  const [currentPage, setPage] = useState(8)
  const mainCategory = useRef();
  const [displayCalendar, setDisplayCalendar] = useState(false)
  const [selectedDateV2, setSelectedDateV2] = useState(momentTZ(setHours(setMinutes(new Date(), 0), 9)).add(4, 'days').tz("Asia/Manila").toDate())
  // const [randomproducts, setRandomProducts] = useState([])
  const [suggesteItem, setSuggestedItem] = useState({
    type: secondProduct,
    displayName: secondProduct,
    title:secondProduct
  })
  
var addonsID = 'Tart-Addons'
  const onScroll = () => {
    // if (listInnerRef.current) {
    //   const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    //   console.log("reached bottom");
    //   if (scrollTop + clientHeight === scrollHeight) {
    //     console.log("reached bottom");
    //   }
    // }
  };
  const finder = (e) => {
    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)
    if (e != undefined) {
      var filterd =  e.filter( data => moment(data.date).format("YYYY-MM-DD") === moment(date.date).format("YYYY-MM-DD"))
      if (filterd.length){
        return filterd[0]
      }
    } 
  }

  const productFilter = (e,state)=> {
    var filtered =  []
    e.map((item)=>{
      var price = finder(item.priceSurgesDates)
      if (item.priceSurgesDates != undefined &&  item.priceSurgesDates.length && price != undefined ) {
          console.log()
        item.basePrice = item.price
        item.price = price.price
        
        filtered.push(item)
      }else {
        filtered.push(item)
      }
    }) 
    state(filtered)
    setStatus(false)
    setSuggestStatus(false)

  }
  const setOneDayAdvanceDelivery =()=>{
    var startDate = moment(setHours(setMinutes(new Date(), 0), 13)).add(1, 'days').toDate()
    var searchDetails = {
      date:startDate,
      type:primaryProduct
    }
    setSelectedDate(startDate)
    localStorage.setItem('userQ', JSON.stringify(searchDetails));
   
  }
  React.useEffect(async () => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)
    if (date == undefined) {
      setOneDayAdvanceDelivery()
    }else { 
      
      if (moment(date.date).add(1, 'days').toDate() <= moment(setHours(setMinutes(new Date(), 0), 13)).add(1, 'days').toDate()) {
        setOneDayAdvanceDelivery()
      }else {
        setSelectedDate(date) 
      }
    }
    
    try {
      window.onscroll = () => {
        listInnerRef.current = window.pageYOffset
        console.log('window.pageYOffset', listInnerRef.current)
        setOffset(window.pageYOffset)
      } 
      fetchStoreProduct(category,8).then(data => {
        if (data === undefined) {
          toast('Please try again later..')
        } else {
          productFilter(data.data.results,setProducts)
          setRandomItems(data.data.results)
        }
      })
      fetchStoreProduct(suggesteItem.type,8).then(data => {
        if (data === undefined) {
          toast('Please try again later..')
        } else {
          productFilter(data.data.results,setSuggestedProduct)
          setRandomItems(data.data.results)
        }
      })
      loadProductCategory()
      let existingCart = localStorage.getItem('UserCart')
      if (existingCart !== null) {
        let jsonCart = JSON.parse(existingCart)
        setCartItems(jsonCart)
      }

    } catch (error) {
      console.log('error',error)
      toast('Please try again later..')
    }
  }, [])

  function navigateTo(){
    storeRef.current.scrollIntoView()
    // window.onscroll = () => {
    //   listInnerRef.current = window.pageYOffset
    //   // console.log('window.pageYOffset', listInnerRef.current)
    //   setOffset(window.pageYOffset)//
    // } 
  }
  function addRemoveItem(e, status) {
    if (status) {
      toast.success(`${e.title.substring(0,4)}... has been added `)
      setCartItems(prevArray => [...prevArray, e])
      let existingCart = cart
      if (cart.length) {

        let existingCart = localStorage.getItem('UserCart')
        let jsonCart = JSON.parse(existingCart)
        jsonCart.push(e)
        localStorage.setItem('UserCart', JSON.stringify(jsonCart));
        localStorage.setItem('UserCart_Cache', JSON.stringify(jsonCart));
      } else {
        let initialCart = []
        initialCart.push(e)
        localStorage.setItem('UserCart', JSON.stringify(initialCart));
        localStorage.setItem('UserCart_Cache', JSON.stringify(initialCart));
      }
    } else {
      let filteredCart = cart.filter(item => item._id !== e._id)
      setCartItems(filteredCart);

      localStorage.setItem('UserCart', JSON.stringify(filteredCart));
    }
    setUpdate(true) 
  }

  function loadSuggestedOnly(type,page){
    try {
      fetchStoreProduct(type,page).then(data => {
        setSuggestedProduct([])
        productFilter(data.data.results,setSuggestedProduct)
      })
    }catch (error) {
      setSuggestedProduct([])
    }
  }
  function reload(type,page,withSuggested) {
    ReactGA.outboundLink(
      {
        label: `User searched item : ${type,JSON.parse(localStorage.getItem('userQ'))}`
      },
      function () {
        console.log('');
      },
      ['Search-Tracker']
    );
    
    if (!withSuggested) {
      mainCategory.current = type
    }
    localStorage.setItem('UserCategory_Cache', JSON.stringify(type));
    setDisplaySearched(false)
    setSearchedItem(null)
      setActiveSearch(false)
      setStatus(true)
      setSelectedCategory(type)
      fetchStoreProduct(type,page).then(data => {
        setProducts([])
        // setProducts(data.data.results)
        productFilter(data.data.results,setProducts) 
        // window.scrollTo(0, 1020)
      }) 
      if (withSuggested) {
        let suggested = categories.filter(data => data.title != type && data.status == true)
        var item = suggested[Math.floor(Math.random() * suggested.length)] 
        try {
          console.log("item",item)
          setSuggestedItem(item)
          fetchStoreProduct(item.title,page).then(data => {
            setSuggestedProduct([])
            setSuggestedProduct(data.data.results,setProducts)
          })
        }catch (error) {
          setSuggestedProduct([])
        }
        
      } 
  }
  function loadProductCategory() {
    let parameter = {
      id: id,
      equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
    }
    axios
      .post("/details/Store", parameter)
      .then((response) => { 
        // setSelectedCategory(response.data.results[0].productCategories.filter(item => item.status === true))
        setCategory(response.data.results[0].productCategories)
        // setTopItems(response.data.results[0].topItems)
        console.log('response.data.results[0]',response.data.results[0])
        setStoreInfo(response.data.results[0]) 
        setHasVoucher(response.data.results[0].storeOptions.siteHeaderNotice.status)
        setFeaturedProducts(response.data.results[0].storeOptions.featuredProducts)
        document.title = response.data.results[0].storeName
        localStorage.setItem('featured_items', JSON.stringify(response.data.results[0].storeOptions.featuredProducts))
        localStorage.setItem('store_details', JSON.stringify(response.data.results[0]))
        
      })
      .catch(function (error) {
        console.log('fetching product', error)
      });
  }
  async function fetchStoreProduct(item,page) {
    try {
      var defaultDate =  new Date()
      var convertedUserDate = moment(defaultDate).format("yyyy-mm-dd")
      let userSeletedDate = localStorage.getItem('userQ')
      var date = JSON.parse(userSeletedDate) 
      setSelectedDate(date)
      console.log('convertedUserDate',convertedUserDate)
      const data = { id: item, queryType: "filter", storeOwner: '5ff00ddaeb2f5d0940dfa186', isAPI: true,showLimit:true,number:page ,deliveryDate:moment(date.date).format("YYYY-MM-DD")}
      const response = await axios.post('/productV2/Product', data)
      return response
    } catch (error) {

      toast('Please try again later..')
    }
  }
  function renderCategories() {
    var data = []
    categories.map(item => {
      if (item.status) {
        data.push(
          <CategoryView onClick={(e) => reload(e,8)} active={category} data={item} />
        )
      }
    })
    return data
  }
  function renderItem(source) {
    var data = []
    var sortedProduct = source.sort((a, b) => parseInt(a.price) - parseInt(b.price));
    var sourceItem = sortedProduct
    var activePromo = storeDetails.storeOptions.activeCategoryPromo[0].category
    if (sourceItem.length) {
      sourceItem.map(item => {
        var isAdded = cart.filter(data => data._id === item._id).length === 0 ? false : true
        data.push(
          <>
            <CardV2 activePromo={activePromo} type="Display" view={(e) => setCurrentView(e)} update={didUpdate} existing={isAdded} item={item} didAdd={(item) => addRemoveItem(item, true)} didRemove={(item) => addRemoveItem(item, false)}  />
            {/* <Card activePromo={activePromo} type="Display" view={(e) => setCurrentView(e)} update={didUpdate} existing={isAdded} item={item} didAdd={(item) => addRemoveItem(item, true)} didRemove={(item) => addRemoveItem(item, false)} /> */}
          </>
        )
      })
      return data
    } else {
      for (var i = 0; i < 4; i++) {
        data.push(
          <PlaceholderCard />
        )
      }
      return data
    }
  }

  const displayNotice = () => {
    var content = <div></div>
    if (storeDetails !== null && storeDetails.storeOptions.siteHeaderNotice.status) {
      var details = storeDetails.storeOptions.siteHeaderNotice
      
      content = (<div className="sticky top-0 z-50 flex-1 overflow-y-auto  lg:h-15 sm:h-10 transform transition duration-400 " style={{ backgroundColor: storeDetails.storeOptions.colorTheme.componentColor }}>
        <div className="flex ... items-center justify-center w-full  mt-2 uppercase text-4 tracking-tight  font-extralight text-gray-900 " >
          {details.value}
        </div>
      </div>)
    }

    return content
  }

  const loadItem =(item,page,primary)=>{
    setPage(page)
    if (primary) {
      reload(item,page,false)
    } else {
      loadSuggestedOnly(item,page,true)
      setSuggestStatus(true)
    }
  }

  const validateCart =()=>{
    let existingCart = localStorage.getItem('UserCart')
    if (existingCart !== null) {
      let jsonCart = JSON.parse(existingCart)
      setCartItems(jsonCart.length == 0  ? []  : jsonCart)
    }else {
      setCartItems([])
    }
  }
  const searchService =()=>{
    let parameter = {
      owner: id,
      value: searchedItem,
      deliveryDate: new Date()
    }
    setActiveSearch(false)
    setStatus(true)
    axios
      .post("/smartSearchV2", parameter)
      .then((response) => { 
        setStatus(false)
        setActiveSearch(true)
        if (response.data.results.length ){
          // setProducts([])
          setSearchResults([])
          setDisplaySearched(true)
          productFilter(response.data.results,setSearchResults)
        }else{
          setSearchResults([])
          setDisplaySearched(false)
        }
        
        // setSelectedCategory(response.data.results[0].productCategories.filter(item => item.status === true))
        // setCategory(response.data.results[0].productCategories)
        // // setTopItems(response.data.results[0].topItems)
        // console.log('response.data.results[0]',response.data.results[0])
        // setStoreInfo(response.data.results[0]) 
        // setHasVoucher(response.data.results[0].storeOptions.siteHeaderNotice.status)
        // document.title = 'Search results'
      })
      .catch(function (error) {
        console.log('fetching product', error)
      });
  }
  const saveDateState = (startDate,title) =>{
    setSelectedDateV2(startDate)
   var convertedTime = moment(startDate).toDate()
   var searchDetails = {
     date:convertedTime,
     type:category
   }
   localStorage.setItem('userQ', JSON.stringify(searchDetails));
  
 }
  const searchAvailableDateService=(e)=>{
    setDisplayCalendar(false)
    localStorage.removeItem('UserCart')
    validateCart()
    reload(category,currentPage,true)
  }
  const searchComponent =()=>{
    let searchResultContent = (
      <section>
        
        <div className='mt-10 mb-10'/>
<div className= { displayCalendar ? 'visible mt-2 transform transition duration-400' :'hidden' }>
<div className=' grid grid- -1 ml-8  lg:grid-cols-3 md:grid-cols-5 gap-2 content-center'>
<div className='w-full'/>
<div className="lg:ml-20 md:ml-20 sm:ml-2 border border-gray-100 w-80 shadow-lg rounded-lg content-center justify">
<DatePickerComponent date={selectedDateV2}  onChangeDate={(e)=> saveDateState(e) } validate={()=>validateCart()}  store={storeDetails} />
<button onClick={searchAvailableDateService} className='text-blue-700   mb-2 font-bold  w-full rounded-full mb'>
  Search available flowers
</button>
</div>
<div className='w-full'/>
</div>
</div>

  <div class="max-w-screen-xl px-4 py-8 group group-hover:text-red-200 mx-auto">
    
  {storeDetails === null ? null : 
  <span className="flex items-center ">
     {/* <h5 class=" text-sm  text-black/90">Available items for <span className="text-blue-400 ml-2"> {moment(selectedDate.date).format("MMMM DD YYYY").toString()}</span></h5> 
     <img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /> */}
     </span> 
     }
     <div>
      <span class="inline-block w-12 h-1 bg-red-200"></span>
          <h2 class="mt-1 text-2xl font-extrabold  tracking-wide uppercase lg:text-3xl">
         {
           searchedItem
          }
      </h2>
      <h5 class=" text-sm  text-gray-500">{searchedResults.length} items found</h5>
    </div>
    <div class="grid grid-cols-2 mt-8 lg:grid-cols-4 gap-x-4 gap-y-8">
    {storeDetails != null ? renderItem(searchedResults) : null}
    </div>
  </div>
</section>
    )

    let emptyResults = (
      <a href="javascript:void(0);" onClick={()=>setActiveSearch(false)} >
      <div  className='rounded-3xl  max-w-md mx-auto relative items-center  mb-20 justify-center text-center w-full mt-10'>
        <span className='text-xl font-bold  items-center '>'{searchedItem}'</span>
      <img
      className='ml-2'
       src="https://cdn.dribbble.com/users/2387623/screenshots/7026223/media/b6d3d311773a99fc8515c591cd069909.jpg?compress=1&resize=1600x1200&vertical=top"
        width={400}
        height={400}
      />
       <span className='text-xl font-bold  items-center'>Not found</span>
    </div>
    </a>
    )
    const validateKeyboard = (e)=>{
      if (e.key === 'Enter') {
        searchService()
      }
 
    }

    return  ( 


    <> 
    <div class='max-w-md mx-auto '>
</div>
<h5 class=" text-sm lg:ml-32  md:ml-4 ml-32 text-black/90 mb-2"></h5><span><h5 class="animate-bounce text-xs lg:ml-32 ml-4 text-black/20 ">Tap to change the date</h5></span>
<div
showSoftInputOnFocus={false}
      onClick={()=>setDisplayCalendar(true)}
      maxLength={30}
      // style={{borderColor:storeDetails === null ? 'white':storeDetails.storeOptions.colorTheme.buttonColor}}
      class="peer  bg-blue-800 align-center justify-center items-center  flex   hover:shadow-lg  mt-2 ease-in-out duration-300  w-auto h-10 lg:ml-32 ml-4 lg:w-60 outline-none text-sm text-blue-800 font-bold rounded-full border mt mb pl-4 text-white "
      type="text"
      id="searchV2"
      disabled={displayCalendar}
      value={moment(selectedDateV2).format("MMMM DD YYYY").toString() }//"Choose Date "
      // disabled={status}
      onKeyDown={(e)=>validateKeyboard(e)}
      onChange={(e)=>setSearchedItem(e.nativeEvent.target.value)}
      
      placeholder="Choose Date" >
        <span className=' text-white'>Order for {moment(selectedDateV2).format("MMM DD, YYYY").toString()} </span>
        <img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 ml-2 rounded-sm bg-white " />
        </div> 
<div className='mt-10 mb-10'/>
<div className= { displayCalendar ? 'visible mt-2 transform transition duration-400' :'hidden' }>
<div className=' grid grid-cols-1 ml-8  lg:grid-cols-3 md:grid-cols-5 gap-2 content-center'>
<div className='w-full'/>
<div className="lg:ml-20 md:ml-20 sm:ml-2 border border-gray-100 w-80 shadow-lg rounded-lg content-center justify">
<DatePickerComponent date={selectedDateV2}  onChangeDate={(e)=> saveDateState(e) } validate={()=>validateCart()} store={storeDetails} />
<button onClick={searchAvailableDateService} className='text-blue-700   mb-2 font-bold  w-full rounded-full mb'>
Search available flowers
</button>
</div>
<div className='w-full'/>
</div>
</div>
<div className='mt-10 mb-10'/>
 





<h5 class=" text-sm lg:ml-32  md:ml-4 ml-32 text-black/90 mb-2"></h5><span><h5 class=" text-xs lg:ml-32 ml-4 text-black/20 ">Search by category</h5></span>
{displayCalendar ? null:renderCategory()}
{searchedResults.length === 0 ? isActiveSearch ?  emptyResults : null :null } 

{isDisplaySearched  ?  searchResultContent : null }
<div className='lg:mb-20 md:mb-40 mb-20'/>
    </>
  )
//     return (
//       <> 
//       <div class='max-w-md mx-auto '>
//     <span className='ml-4 mb-10 '>Searching for: <span className='font-bold'>{searchedItem}</span></span>
//     <div class="relative ease-in-out duration-300 flex items-center w-90 lg:w-full md:w-full h-12 border-2 border-gray-500 rounded-full focus-within:shadow-lg hover:shadow-lg hover:border-black  bg-white overflow-hidden">
//          <input
//          maxLength={30}
//         class="peer h-full w-full outline-none text-sm text-gray-700 pl-4"
//         type="text"
//         id="search"
//         disabled={status}
//         onKeyDown={(e)=>validateKeyboard(e)}
//         onChange={(e)=>setSearchedItem(e.nativeEvent.target.value)}
//         placeholder="Search for flowers/balloon" /> 
//         <div class="grid place-items-center h-full w-12 text-gray-300">
//           {status ? <div className="">
//          <svg version="1.1" id="loader-1" className="animate-spin h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
//          width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" space="preserve">
//          <path opacity="0.2" fill="#301B30" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
//            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
//            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
//          <path fill="#301B30" className="border-white border-2" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
//            C22.32,8.481,24.301,9.057,26.013,10.047z">
//            </path>
//          </svg>
//        </div> : <a href="javascript:void(0);" onClick={()=>searchService()} >
//             <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
//             </svg>
//             </a> }
//         </div>
//     </div>

// {searchedResults.length === 0 ? isActiveSearch ?  emptyResults : null :null } 
// </div>
// {isDisplaySearched  ?  searchResultContent : null }
// <div className='lg:mb-40 md:mb-40 mb-20'/>
//       </>
//     )
  }
  const displayMainProduct = ()=>{
    try {
      return (
        <div className="sm:text-center text-center lg:text-center  margin: 20rem my-40  " >
        <h1 className="uppercase  text-4xl tracking-tight  font-extralight text-gray-900 sm:text-2xl md:text-6xl">
          <span className="block xl:inline">{
            categories.length ? categories.filter(data => data.title == category)[0].displayName : null
          }</span>{' '}
          <span className="block text-themebg-600 xl:inline">Collection</span>
        </h1>
        <span className="block text-themebg-600 xl:inline mt-10">{product.length} items found</span>
        
      </div>
      )
    } catch (error) {
      return (<span></span>)
    }
  }
  const renderCategory = ()=>{

    // return (
    //   <div className='ml-20'>
      
    //   {renderDropDown()}
    //   </div>
    //   )
    var list = []
    try {
      storeDetails.productCategories.map((item)=>{
        if(item.status) {
          list.push(<>
            {categoryComponent(item)}
            </>   
            )
        }
      })    
    }catch {
      return null
    }
    return<div><div className="w-11/12  lg:ml-28 md:ml-20 overflow-x-visible whitespace-nowrap scroll-smooth  lg:whitespace-normal overflow-auto items-center ">
    
      {/* <div style={{overflow: "auto",whiteSpace: "nowrap"  }}>  */}
      {list}
      {/* </div> */}
   
    </div>
    {/* <div className="float-right " style={{top:20,right:20}}>
        Here
        </div> */}
     </div> 
  }
  const categoryComponent = (item)=>{
    return (
      
      <button
      onClick={()=>reload(item.title,currentPage,true)}
      disabled={ status === false ? false : true}
      type="button"
      className= {1  !== 0 ?  "my-2 inline-flex hover:scroll-auto  hover:shadow-lg border-black justify-center px-4 py-2 text-sm font-medium ml-4 text-white border border-transparent rounded-full ease-in-out duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  :"focus:ring-indigo-500 inline-flex   justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-gray-200 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  }
      style={{
        opacity: status ? category === item.title ?1 : 0.4:1,
        backgroundColor:category === item.title ? 'black': 'white', borderColor:category === item.title ?  null:storeDetails === null ? 'white':storeDetails.storeOptions.colorTheme.buttonColor,color:category === item.title ? 'white' :'black'}}
    >  
    {status ? <> <div className=""><span className="font-sm " >{item.displayName}</span></div>
    {category != item.title  ? <div className=""><span className="font-sm ml" >+</span></div> : <div className="">
     <svg version="1.1" id="loader-1" className="animate-spin h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" space="preserve">
     <path opacity="0.2" fill="#FFFF" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
       s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
       c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
     <path fill="#FFFF" className="border-white border-2" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
       C22.32,8.481,24.301,9.057,26.013,10.047z">
       </path>
     </svg>
   </div> }
    </>: <><div className=""><span className="font-sm " >{item.displayName} +</span></div>
    <a href="javascript:void(0);" >
    
      </a> </>}
    </button>
    )


    {/* <svg xmlns="http://www.w3.org/2000/svg" className="lg:ml-2 md:ml-2 h-5 w-5" fill="none" viewBox="0 0 20 20" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
</svg> */}
  }

  const renderCategoryDisplayName = ()=>{
     try {
      return categories.length ? categories.filter(data => data.title == category)[0].displayName : null
     } catch (error) {
      return <>....</>
      
     }
  }
  const mainContent = () =>{
    return <div ref={storeRef} >
      {/* <img
          alt="Party"
          src={storeDetails != null ? storeDetails.welcomeMessageV2.landingImage : ''}
          class=" inset-0 h-64 w-full ml-20 mr-20 object-cover"
        /> */}

      {/* <div class="bg-cover bg-center max-h-1 " styles={`background-image: url(${storeDetails != null ? storeDetails.welcomeMessageV2.landingImage : ''})`}></div> */}
      {/* <img src={storeDetails != null ? storeDetails.welcomeMessageV2.landingImage : ''} className='max-w-screen-xl  rounded-lg  px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8 bg-center ' style={{height:600}}/> */}
      {/* {displayRandomItem()} */}
      <div className=' bg-gray-200 mr-auto ml-auto ' style={{height:1.1,width:'90%'}}/>
      {/* {displayNotice()} */}
      {1 > 300 ? null : <div className="sm:bg-white bg-white  xs:bg-white md:bg-red lg:bg-white">
      {/* <FixedDatePicker  storeDetails={storeDetails} validate={()=>validateCart()}className="" tapped={(selectedDate,item)=>reload(item,currentPage,true)} categories={categories} isLoading={status} /> */}
        {/* {storeDetails !== null ? <div><LandingHeader didScroll={() => window.scrollTo(0, 1020)} details={storeDetails} /> </div>: <MyLoader /> } */}
        {/* {storeDetails !== null ?  <FixedDatePicker storeDetails={storeDetails} validate={()=>validateCart()}className="" tapped={(selectedDate,item)=>reload(item,currentPage,true)} categories={categories} isLoading={status} />: null} */}
        {/* hidden sm:visible md:visible */}
        <div class="grid grid-col-3 grid-flow-col gap-3 ">
  <div class="w-full"/>
{/* <img class="lg:h-20  h-20 w-96  lg:w-96" src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1694837899463-we-accept.png" alt="Man looking at item at a store"/> */}

</div>
<div className='mt-20 h-20 w-full'/>
      {/* <div class="mt-20overflow-hidden shadow-lg bg-white hidden md:visible rounded-lg h-90 w-60 md:w-80 cursor-pointer m-auto " style={{marginTop:100}}>
    <a href="#" class="w-full block h-full">
        <img alt="blog photo" src={storeDetails != null ? storeDetails.welcomeMessageV2.landingImage : ''} class="max-h-40 w-full object-cover"/>
        <div class="bg-white dark:bg-gray-800 w-full p-4">
            <p class="text-indigo-500 text-md font-medium">
            </p>
            <p class="text-gray-800 dark:text-white text-xl font-medium mb-2">
                {storeDetails != null ? storeDetails.welcomeMessageV2.header : ''}
            </p>
            <p class="text-gray-400 dark:text-gray-300 font-light text-md">
            {storeDetails != null ?  storeDetails.welcomeMessageV2.subDetails : ''}
            </p>
        </div>
    </a>
</div> */}
 
  {searchComponent()}
  {/* {renderCategory()} */}
  
        {/* <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div class="md:flex">
    <div class="md:shrink-0">
      <img class="h-48 w-full object-cover md:h-full md:w-48" src="/img/store.jpg" alt="Man looking at item at a store"/>
    </div>
    <div class="p-8">
      <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Case study</div>
      <a href="#" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Finding customers for your new business</a>
      <p class="mt-2 text-gray-500">Getting a new business off the ground is a lot of hard work. Here are five ideas you can use to find your first customers.</p>
    </div>
  </div>
</div> */}

        </div>}

      { cart.length >= 1 ? <CartView  storeDetails={storeDetails} removeItem={(item) => addRemoveItem(item, false)} items={cart} /> : null }
{/* {displayMainProduct()} */}
{/* listInnerRef.current >= 300 ?  */}
      {/* <div className="sm:text-center  text-center lg:text-center  margin: 20rem my-40  " >
        <h1 className="uppercase  text-4xl tracking-tight  font-extralight text-gray-900 sm:text-2xl md:text-6xl">
          <span className="block xl:inline">{
            categories.length ? categories.filter(data => data.title == category)[0].displayName : null
          }</span>{' '}
          <span className="block text-themebg-600 xl:inline">Collection</span>
        </h1>
        <span className="block text-themebg-600 xl:inline mt-10">{product.length} items found</span>
      </div> */}
      <div  style={{opacity:status ? 0.2 : 1}}>
        <div class="divide-y divide-gray-200">
          <div>
          {/* {storeDetails === null ? null : <span className="ml-20 flex items-center text-gray-500">Available items for <span className="text-blue-400 ml-2"> {moment(selectedDate.date).format("MMMM DD YYYY").toString()}</span><img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /></span> } */}
          <div class="grid gap-0 lg:grid-cols-4  md:grid-cols-3  sm:grid-cols-2" >
              {/* {storeDetails != null ? renderItem(product) : null} */}
            </div>
            {/* {storeDetails === null ? null : <span className=" flex items-center text-gray-500">Available items for <span className="text-blue-400 ml-2"> {moment(selectedDate.date).format("MMMM DD YYYY").toString()}</span><img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /></span> }
    <div>
      <span class="inline-block w-12 h-1 bg-red-200"></span>
          <h2 class="mt-1 text-2xl font-extrabold tracking-wide uppercase lg:text-3xl">
          { suggesteItem.displayName} 
      </h2>
      <span className="block text-themebg-600 xl:inline mt-10">{suggestedProduct.length} items found</span>
    </div> */}
   <section>

  <div class="max-w-screen-xl px-4 py-8 group group-hover:text-red-200 mx-auto">
  {storeDetails === null ? null : 
  <span className="flex items-center "> <h5 class=" text-sm  text-black/90">Available items for <span className="text-blue-800 ml-2   rounded-full m"> <span className='m-2'>{moment(selectedDate.date).format("MMM DD, YYYY").toString()}</span></span></h5> <img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /></span> }
  
    <div>
      <span class="inline-block w-12 h-1 bg-red-200"></span>
          <h2 class="mt-1 text-2xl font-extrabold tracking-wide uppercase lg:text-3xl">
            {renderCategoryDisplayName()} 
      </h2>
      <h5 class=" text-sm  text-gray-500">{product.length} items found</h5>
    </div>
    <div class="grid grid-cols-2 mt-8 lg:grid-cols-4 gap-x-4 gap-y-8">
    {storeDetails != null ? renderItem(product) : null}
    </div>
  </div>
</section>




            <div className="text-center  mb-10"  >
          
            <button
            disabled={status}
            onClick={()=>loadItem(categories.filter(data => data.title == category)[0].title,currentPage + 100,true)}
             type="button"
             className= { "my-2 inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-black border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"   }
             style={{backgroundColor:storeDetails === null ? 'white':storeDetails.storeOptions.colorTheme.buttonColor}}
              >  
               Show More  {status ?  <div >
                          <svg version="1.1" id="loader-1" className="animate-spin h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" space="preserve">
                          <path opacity="0.2" fill="#FFFF" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                          <path fill="#FFFF" className="border-white border-2" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                            C22.32,8.481,24.301,9.057,26.013,10.047z">
                            </path>
                          </svg>
                        </div> :null
                        }
               </button>
              </div>
 
{/* <section class="relative overflow-hidden mt-20 mb-20 lg:m-0 rounded-lg pb-80 shadow-2xl lg:pb-0">
  <div class="ml-auto p-8 text-center sm:p-12 lg:w-2/3">
    <p class="text-sm font-semibold uppercase tracking-widest">
      Run with the pack
    </p>

    <h2 class="mt-6 font-black uppercase">
      <span class="text-5xl font-black sm:text-6xl">Wide payment options</span>
      <span class="mt-2 block text-sm"></span>
    </h2>

    <a
      class="mt-8 inline-block w-full bg-black py-4 rounded-full text-sm font-bold uppercase tracking-widest text-white"
      href="https://apps.apple.com/us/app/id1552610226"
    >
      USE APP
    </a>

    <p class="mt-12 text-xs font-medium uppercase text-gray-400">
     *Simply place your order*
    </p>
  </div>

  <div class="absolute bottom-0  left-0 h-80 w-full lg:h-full lg:w-1/3">

    <img
      alt="Trainer"
      src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1696041349199-51191a0c6396fee26678f8a7dc17b40b.png"
      class="absolute inset-0 h-full w-full object-cover"
    />
  </div>
</section>   */}

            <section>
              
  <div class="max-w-screen-xl px-4 py-8 mx-auto">
  {storeDetails === null ? null : <span className=" flex items-center ">  <h5 class=" text-sm  text-black/90">Available items for <span className="text-blue-400 ml-2"> {moment(selectedDate.date).format("MMM DD, YYYY").toString()}</span></h5><img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /></span> }
    <div>
      <span class="inline-block w-12 h-1 bg-red-200"></span>
          <h2 class="mt-1 text-2xl font-extrabold tracking-wide uppercase lg:text-3xl">
          { suggesteItem.displayName} 
      </h2>
      <h5 class=" text-sm  text-gray-500">{suggestedProduct.length} items found</h5>
      
    </div>
    <div class="grid grid-cols-2 mt-8 lg:grid-cols-4 gap-x-4 gap-y-8">
    {storeDetails != null ? renderItem(suggestedProduct) : null}
    </div>
  </div>
</section>

           
            <div className="text-center mb-20 "  >
            <button
            disabled={status}
            onClick={()=>loadItem(suggesteItem.title,currentPage + 20,false)}
             type="button"
             className= { "my-2 inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white  border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"   }
              style={{opacity:status?0.0:1,backgroundColor:storeDetails === null ? 'white':storeDetails.storeOptions.colorTheme.buttonColor}}>  
               Show More  {suggestStatus ?  <svg version="1.1" id="loader-1" className="animate-spin h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" space="preserve">
  <path opacity="0.2" fill="#FFFF" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
  <path fill="#FFFF" className="border-white border-2" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z">
    </path>
  </svg> : null}


               </button>
               
              </div>





            {/* <div className="sm:text-center text-center lg:text-left-1 sticky margin: 20rem  mt-20 " >
              <h1 className="uppercase text-4xl tracking-tight font-extralight text-gray-900 sm:text-2xl md:text-6xl">
                <span className="block xl:inline">HOTTEST </span>
                <span className="block text-themebg-600 xl:inline">Collection</span>
              </h1>
            </div>
            <div class="grid  gap-0  lg:grid-cols-4   md:grid-cols-3  sm:grid-cols-2 " >
            {storeDetails != null ?  renderItem(topItems) : null}
            </div> */}

          </div>
        </div>

        {/* <div className="text-black flex flex-col items-center mt-40 mb-10 f-f-l pt-3">
            <h1 className="text-1xl uppercase font-extralight text-gray-500" >Mode of Payments</h1>
            <div className="my text-base text-color f-f-l">
                <ul className="flex items-center">
                  <div class="hover:opacity-60 ease-in-out duration-300  hover:shadow-lg opacity-100 w-col w-col-3 w-col-small-6 w-col-tiny-6 mr-2">
                  <img src="https://mindanaotimes.com.ph/wp-content/uploads/2021/05/GCash-Logo-Transparent-PNG-1.png" loading="lazy" width="100" alt="" className='rounded-full '/>
                  </div>
                  <div class="hover:opacity-60 ease-in-out duration-300 hover:shadow-lg w-col w-col-3 w-col-small-6 w-col-tiny-6 mr-2">
                  <img src="https://cdn.pixabay.com/photo/2015/05/26/09/37/paypal-784404_960_720.png" loading="lazy" width="100" alt=""/>
                  </div>
                  <div class="hover:opacity-60 ease-in-out duration-300  hover:shadow-lg w-col w-col-3 w-col-small-6 w-col-tiny-6 mr-2">
                  <img src="https://businessweekmindanao.com/wp-content/uploads/2022/07/bpi-logo-bank-of-the-philippine-islands.png" loading="lazy" width="100" alt=""/>
                  </div>
                  <div class="hover:opacity-60 ease-in-out duration-300  hover:shadow-lg w-col w-col-3 w-col-small-6 w-col-tiny-6 mr-2">
                  <img src="http://www.gtcapital.com.ph/storage/uploads/2017/09/59bc94ce59565.png" loading="lazy" width="100" alt=""/>
                  </div>
                </ul>
            </div>
        </div> */}
        {/* {storeTimeline()} */}
        {storeDetails !== null ? <Footer details={storeDetails} /> : <MyLoader />}
        <PopupProduct
        didAdd={(item) => addRemoveItem(item, true)}
         storeDetails={storeDetails} removeItem={(item) => addRemoveItem(item, false)} items={[1,2,3,4,5]} />
      </div>

    </div>
  }
  const storeTimeline = ()=>{
    return (
<section >
  <div class="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
    {/* <h1 className='lg:text-9xl md:text-9xl text-5xl  font-bold mt-10 lg:mt-0 md:mt-0 '>A decade of devoted service.</h1> */}
    <ul class="grid grid-cols-1 gap-4 mt-8 lg:grid-cols-3">
      <li className=' hover:opacity-75'>
        <a href="javascript:void(0);"
        onClick={()=>navigateTo()}
        class="relative block group">
          <img
            src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1678896975140-309929403_519932946806916_4682159831488024056_n.jpeg"
            alt=""
            class="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            class="absolute inset-0 flex  flex-col items-start justify-end p-6"
          >
            <h3 class="text-xl font-medium text-white">Corporation</h3>

            <span
              class="mt-1.5 inline-block rounded-full bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Shop Now
            </span>
          </div>
        </a>
      </li>

      <li className=' hover:opacity-75'>
      <a href="javascript:void(0);"
        onClick={()=>navigateTo()}
        class="relative block group">
          <img
            src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1678896914875-329374368_585819756384095_4700813843047961952_n.jpeg"
            alt=""
            class="object-cover w-full  rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            class="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
            <h3 class="text-xl font-medium text-white">SME's</h3>

            <span
              class="mt-1.5 inline-block bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Shop Now
            </span>
          </div>
        </a>
      </li>

      <li class="lg:col-span-2 hover:opacity-75 lg:col-start-2 lg:row-span-2 lg:row-start-1">
      <a href="javascript:void(0);"
        onClick={()=>navigateTo()}
         class="relative block group">
          <img
            src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1678895558917-282211211_1893462097514057_8466299064680741685_n.jpg"
            alt=""
            class="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            class="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
            <h3 class="text-xl font-medium text-white">Government</h3>

            <span
              class="mt-1.5 inline-block bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Shop Now
            </span>
          </div>
        </a>
      </li>


      <li class="lg:col-span-2 hover:opacity-75 lg:col-start-2 lg:row-span-2 lg:row-start-1">
      <a href="javascript:void(0);"
        onClick={()=>navigateTo()}
         class="relative block group">
          <img
            src="https://scontent.fmnl9-1.fna.fbcdn.net/v/t39.30808-6/384745355_797857929014415_3230022245281773718_n.jpg?stp=cp6_dst-jpg&_nc_cat=110&ccb=1-7&_nc_sid=5614bc&_nc_eui2=AeENJqXSMEnJvmk5UzNdBMRZqm8fuHQ5Wkeqbx-4dDlaR956WUn-OVjgLVcUPl-irYrCGVkaglnl0B7I02xCS6yl&_nc_ohc=jq8pQyGmOSgAX8JUj4K&_nc_zt=23&_nc_ht=scontent.fmnl9-1.fna&oh=00_AfChisuPSMYG2ECP2tqLzVEXCm36B9gY87bKT4Wol6W2VA&oe=651CCC00"
            alt=""
            class="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            class="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
            <h3 class="text-xl font-medium text-white">Inc.</h3>

            <span
              class="mt-1.5 inline-block visible lg:invisible bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
            >
              Shop Now
            </span>
          </div>
        </a>
      </li>



    </ul>
  </div>
</section> 
    )
  }


  let childItems = [{
    "key":1,
    "id": "VALENTINESDAY",
    "title": "VALENTINESDAY",
    "displayName": "VALENTINE'S DAY ",
    "status": false,
    "type": "Main",
    "chosen": false,
    active: false ,
  },{
    active: false ,
    "key":2,
    "id": "MOTHER'SDAY2023",
    "title": "MOTHER'SDAY2023",
    "displayName": "MOTHER'S DAY 2023",
    "status": false,
    "type": "Main",
    "chosen": false
  }]



  let listV2 = [{
    active: false ,
    "key":2,
    "id": "MOTHER'SDAY2023",
    "title": "MOTHER'SDAY2023",
    "displayName": "MOTHER'S DAY 2023",
    "status": false,
    "type": "Main",
    "chosen": false,
    "items":childItems
  },{
    active: false ,
    "key":2,
    "id": "MOTHER'SDAY2023",
    "title": "MOTHER'SDAY2023",
    "displayName": "MOTHER'S DAY 2023",
    "status": false,
    "type": "Main",
    "chosen": false,
    "items":[{
      active: false ,
      "key":2,
      "id": "MOTHER'SDAY2023",
      "title": "MOTHER'SDAY2023",
      "displayName": "MOTHER'S DAY 2023",
      "status": false,
      "type": "Main",
      "chosen": false
    }]
  },{
    active: false ,
    "key":2,
    "id": "MOTHER'SDAY2023",
    "title": "MOTHER'SDAY2023",
    "displayName": "MOTHER'S DAY 2023",
    "status": false,
    "type": "Main",
    "chosen": false,
    "items":childItems
  },{
    active: false ,
    "key":2,
    "id": "MOTHER'SDAY2023",
    "title": "MOTHER'SDAY2023",
    "displayName": "MOTHER'S DAY 2023",
    "status": false,
    "type": "Main",
    "chosen": false,
    "items":childItems
  },{
    active: false ,
    "key":2,
    "id": "MOTHER'SDAY2023",
    "title": "MOTHER'SDAY2023",
    "displayName": "MOTHER'S DAY 2023",
    "status": false,
    "type": "Main",
    "chosen": false,
    "items":childItems
  }]
  const renderDropDown = ()=>{
    let list = []
    listV2.map((item)=>{
      list.push(<DropDownButton items={item.items} />)
    })
    return list
  }
  const addFavouriteItem=(e)=>{
    
  if (validateItem(e) && e.status  ) {
      addRemoveItem(e, true)
    }else {
      // setAdded(false)
    }
  }
  const validateItem = (e)=>{
        
    try {
        let existingCart = localStorage.getItem('UserCart')
        var cart = JSON.parse(existingCart)
        const found = cart.find(obj => {
            return obj._id === e._id;
          });
          
          let status =  found === undefined ? true :false
          return status
    } catch (error) {
        return  true
    }
}

function randomNumberInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function setRandomItems(source){
  try {
    let from = randomNumberInRange(1,source.length)
    let secondLoop = randomNumberInRange(2,source.length - 2)
    let thirdLoop = randomNumberInRange(3,source.length - 3 )
    let fourthLoop = randomNumberInRange(3,source.length - 3 )
    let fifthloop = randomNumberInRange(3,source.length - 3 )
    let sisxthloop = randomNumberInRange(3,source.length - 3 )
    const randomItem = randomNumberInRange(1,source.length)
       let item1 = source[randomNumberInRange(9,source.length)]
       let item2 = source[randomItem]
       let item3 = source[randomItem]
       let item4 = source[randomItem]
       let item5 = source[randomItem]
       let item6 = source[randomItem]
      //  setRandomProducts([source[randomNumberInRange(1,source.length)],
      //  source[randomNumberInRange(1,source.length)],
      //  source[randomNumberInRange(1,source.length)],
      //  source[randomNumberInRange(1,source.length)],
      //  source[randomNumberInRange(1,source.length)],
      //  source[randomNumberInRange(1,source.length)]])
  } catch (error) {
    
  }
}

const priceHandler=(e)=>{
  try {
    if (e.price != undefined) {
      return  new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(e.price)
    }
    
  } catch (error) {
    return null
  }
}
const displayRandomItem = ()=>{
  try {
    
    return (
      <section>
        
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
          <h1 className='lg:text-9xl md:text-9xl text-5xl  font-bold mt-10 lg:mt-0 md:mt-0 '>{storeDetails != undefined ?  storeDetails.welcomeMessageV2.header : "...."}</h1>
          {/* <p className='mt-2 mb-2 text-md'>"Indulge in the beauty of nature with Flower Luxe Cebu's fresh and high-quality flower selections " </p> */}
          
          <ul className="grid grid-cols-2 gap-4 mt-8 lg:grid-cols-3">
            
            <li className=' hover:opacity-75'>
              <a href="javascript:void(0);"
              onClick={()=>addFavouriteItem(featuredProducts[0])}
              className="relative block group">
                <img
                  src={featuredProducts[0].imgUrl}
                  alt=""
                  className="object-cover w-full rounded-lg transition duration-100 aspect-square group-hover:opacity-90"
                />
      
                <div
                  className="absolute inset-0 flex  flex-col items-start justify-end p-6"
                >
             
                <h3 className="text-xl font-medium text-black -mt-24">
               {priceHandler(featuredProducts[0])}
               
               </h3>
                </div>
              </a>
            </li>
      
           

            <li className=' hover:opacity-75'>
              
              <a href="javascript:void(0);"
                onClick={()=>addFavouriteItem(featuredProducts[1])}
                className="relative block group">
                  <img
                  src={featuredProducts[1].imgUrl}
                    alt=""
                    className="object-cover w-full  rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
                  />
                  <div
                    className="absolute inset-0 flex flex-col items-start justify-end p-6 "
                  ><div>
                   <h3 className="text-xl font-medium text-black ">
                   {priceHandler(featuredProducts[1])}
                  
                 </h3>
                    
                    </div>
                   
                  </div>
                </a>
              </li>











      



              {/* RIGHT */}
            <li className="lg:grid grid-row-5 flex  grid-cols gap-4 mt-8 lg:grid-cols-2    w-full">



              
            {/* //LEFT */}
            <div className=''>

            <a href="javascript:void(0);"
              onClick={()=>addFavouriteItem(featuredProducts[2])}
              
               className="relative block group hover:shadow-lg">
                <img
              
                src={featuredProducts[2].imgUrl}
                  alt=""
                  className="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
                />
                <div
                  className="absolute inset-0 flex flex-col items-start justify-end "
                >
              <h3 className="text-md font-medium text-black ">
                {priceHandler(featuredProducts[2])}
               </h3>
                </div>
              </a><a href="javascript:void(0);"
              onClick={()=>addFavouriteItem(featuredProducts[3])}
               className="relative block group">
                <img
              
                src={featuredProducts[3].imgUrl}
                  alt=""
                  className="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
                />
                <div
                  className="absolute inset-0 flex flex-col items-start justify-end "
                >
            
            <h3 className="text-md font-medium text-black ">
            {priceHandler(featuredProducts[3])}
                {/* {featuredProducts[0].title} */}
               </h3>
                  {/* <span
                    className="mt-1.5 inline-block bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
                  >
                   Order me for tomorrow?xxxx
                  </span> */}
                </div>
              </a>

            </div>


            
            {/* RIGHT */}
            
            <div className=''>

<a href="javascript:void(0);"
  onClick={()=>addFavouriteItem(featuredProducts[4])}
   className="relative block group hover:shadow-lg">
    <img
  
    src={featuredProducts[4].imgUrl}
      alt=""
      className="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
    />
    <div
      className="absolute inset-0 flex flex-col items-start justify-end "
    >
  <h3 className="text-md font-medium text-black ">
  {priceHandler(featuredProducts[4])}
   </h3> 

    </div>
  </a><a href="javascript:void(0);"
  onClick={()=>addFavouriteItem(featuredProducts[5])}
   className="relative block group">
    <img
  
    src={featuredProducts[5].imgUrl}
      alt=""
      className="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
    />
    <div
      className="absolute inset-0 flex flex-col items-start justify-end bottom"
    >
  <h3 className="text-md font-medium text-black ">
  {priceHandler(featuredProducts[5])}
   </h3>

      {/* <span
        className="mt-1.5 inline-block bg-black rounded-full px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
      >
       Order me for tomorrow?xxxx
      </span> */}
    </div>
  </a>

</div>



              
            </li>
          </ul>
          
        </div>
        <div className='flex items-center text-center justify-center'>
        {/* <h1 className='mt-4 mb-10 text-md
        '> */}
          <h5 class="mt-4 mb-10  text-sm  text-black/90">
          Featured Products</h5>
        </div>
      </section>)
  } catch (error) {
    return (<button className='text-xs  border-2 h-10 w-auto rounded-full border-black  mt-10 lg:ml-20 ml-4' onClick={() => window.location.reload(true)}><div className='mr-2 ml-2'> Tap to refresh</div></button>)
  }
}
  return (
    <>
<html class="" style={{scrollBeha
:'smooth'}}>
  
  <div className=' w-full '/>
  <div className=' w-full h-20  '>
    <MegaMenu/>
 {/* <div className=' bg-gray-200 mr-auto ml-auto ' style={{height:1.1,width:'90%'}}/> */}
  {mainContent()}
  
  <div className=' w-full h-20 bg-tulips'>
  <div className=' w-full h-20 bg-white rounded-br-full rounded-bl-full'/>
  </div> 
  </div>
  
  {/* <Dropdownv2/>
  <Dropdownv2/> */}
  
  </html>
  <Toaster />
    </>

  );
}

export default App;
 

     {/* <div className={hasVoucher ? "sticky top-5 sm:top-10  z-10 flex-1 overflow-y-auto  lg:h-15 sm:h-10  transform transition duration-400" : "shadow transform transition duration-400 sticky top-0 z-10  lg:h-15 sm:h-10 xs:h-5  "} style={{ backgroundColor: 'white' }}>
        <div class="flex ... items-center   lg:justify-center   overflow-y-auto overflow-x-auto " >
          {categories.length ? renderCategories() : <CategoryPH />}
        </div>
      </div> */}
