import React,{useEffect,Fragment, useState ,useMemo,useCallback} from 'react';
import Footer from './component/Footer' 
import { Dialog, Transition } from '@headlessui/react'
import { BrowserRouter, Route, Link,Switch ,Redirect,useHistory, useLocation ,useSearchParams} from "react-router-dom";
import { axios ,productStats} from './Network'
import toast, { Toaster } from 'react-hot-toast';

import CartView from './component/CartView'
function ProductDetails(props) {
    const location = useLocation()
    const history = useHistory()
    const [show, setShow] = useState(false)
    let [isOpen, setIsOpen] = useState(true)
    let [currentView, setCurrentView] = useState(1)
    const [userDate, setDate] = useState(null)
    const [currentProduct, setCurrentProduct] = useState({
    title:'',subtitle:'',imgUrl:'',status:false
    })
    const [currentImage,setCurrentImage] = useState('')
    const [currentPath, setCurrentPath] = useState(undefined)
    const queryParams = new URLSearchParams(location)
    const [cart, setCartItems] = useState([])
    const [storeDetails, setStoreInfo] = useState(null)
  useEffect(()=>{

    document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    getProduct()
    let existingCart = localStorage.getItem('UserCart')
    let store = localStorage.getItem('store_details')
    console.log('store',store)
    setStoreInfo(JSON.parse(store))
    var cart = JSON.parse(existingCart)
    if (cart != null){

        setCartItems(cart)
    }
  },[currentPath])


  async  function getProduct(){

    try {
      
      var productTitle = decodeURI(window.location.pathname.substring(1))
      const extractedValue = productTitle.split('/').pop();
      if (productTitle != "" ) {
        var parameter =  {isAPI: true,
          queryData: {_id: decodeURI(extractedValue)},
          queryType:  "custom" }
              axios
              .post("/store/Product", parameter)
              .then((response) => { 
                if (response.data.count != 0) {
                    setCurrentProduct(response.data.results[0]) 
                    setCurrentImage(response.data.results[0].imgUrl)
                } else {
                    toast.error('Please try other product.')
                }
              })
              .catch(function (error) {
                console.log('fetching product', error)
              });
      }
       
    } catch (error) {
        return null
    }
    
      }
      const validateItem = (e)=>{
        
        try {
            let existingCart = localStorage.getItem('UserCart')
            var cart = JSON.parse(existingCart)
            const found = cart.find(obj => {
                return obj._id === e._id;
              });
              
              let status =  found === undefined ? true :false
              return status
        } catch (error) {
            return  true
        }
    }

    async function recordProductStats(e,type) {
        try {
            const data = {storeOwner:"PaculbaFlowerShop",cType:"cartBehavior",cName:"website","data":e,"date":new Date(),action:type}
            const response = await productStats.put('/Items', data) 
            return  response
            
        } catch (error) {
        }
      }
  

  function addRemoveItem(e, status) {
   try {
    if (e.status === false) {
        toast.error(`${e.title.substring(0,4)} is currently unavailable.`)
        return
    }
    if (status) {
        toast.success(`${e.title.substring(0,4)}... has been added `)
        setCartItems(prevArray => [...prevArray, e])
    console.log(cart)
        let existingCart = cart
        if (cart.length) {
          let existingCart = localStorage.getItem('UserCart')
          let jsonCart = JSON.parse(existingCart)
          jsonCart.push(e)
          localStorage.setItem('UserCart', JSON.stringify(jsonCart));
        } else {
          let initialCart = []
          initialCart.push(e)
          localStorage.setItem('UserCart', JSON.stringify(initialCart));
        }
      } else {
        let filteredCart = cart.filter(item => item._id !== e._id)
        setCartItems(filteredCart);
        localStorage.setItem('UserCart', JSON.stringify(filteredCart));
      }
   } catch (error) {
        console.log('addremove item',error)
   }
  }
      function addToCart(e){
      
        // if (validateItem(e) && e.status ) {
        //     var variantItem = e
        //     variantItem.selectedColor = color
        //     recordProductStats(e,'Added')
        //     // props.didAdd(variantItem)
        //     setAdded(true)
        // }else {
        //   setAdded(false)
        // }
      }
      const updateImage =(item)=>{
        setCurrentImage(item)
      }
    const renderSubImages =()=>{
        try {
            let content = []
            content.push(
                <a  onClick={()=> updateImage(currentProduct.imgUrl) } href="javascript:void(0);">
            <img  class="lg:w-24 lg:h-24 w-20 h-20 object-cover mr-2 mb-2 hover:shadow-lg rounded-lg" src={currentProduct.imgUrl} alt="Product Image"/>
            </a> )
            currentProduct.productImages.map( (item)=>{
                content.push(
                    <a  onClick={()=> updateImage(item.imgUrl) } href="javascript:void(0);">
                <img  class="lg:w-24 lg:h-24 w-20 h-20 object-cover mr-2 mb-2 hover:shadow-lg rounded-lg" src={item.imgUrl} alt="Product Image"/>
                </a> )
            })
        //     <div className='flex '>
        //     <img class="lg:w-24 lg:h-24 w-20 h-20 object-cover mr-2 mb-2 hover:shadow-lg rounded-lg" src={"https://designerblooms.com.ph/cdn/shop/files/Ecua-Pink-Deluxe_d45b3061-3c50-4ef6-ac8b-aae2159df246_240x.jpg?v=1705029851"} alt="Product Image"/>
        //     <img class="lg:w-24 lg:h-24 w-20 h-20 object-cover mr-2 mb-2  hover:shadow-lg rounded-lg" src={"https://designerblooms.com.ph/cdn/shop/files/Ecua-Pink-Deluxe_d45b3061-3c50-4ef6-ac8b-aae2159df246_240x.jpg?v=1705029851"} alt="Product Image"/>
        //     <img class="lg:w-24 lg:h-24 w-20 h-20 object-cover mr-2 mb-2  hover:shadow-lg rounded-lg" src={"https://designerblooms.com.ph/cdn/shop/files/Ecua-Pink-Deluxe_d45b3061-3c50-4ef6-ac8b-aae2159df246_240x.jpg?v=1705029851"} alt="Product Image"/>
        //     <img class="lg:w-24 lg:h-24 w-20 h-20 object-cover mr-2 mb-2  hover:shadow-lg rounded-lg" src={"https://designerblooms.com.ph/cdn/shop/files/Ecua-Pink-Deluxe_d45b3061-3c50-4ef6-ac8b-aae2159df246_240x.jpg?v=1705029851"} alt="Product Image"/>
        //     <img class="lg:w-24 lg:h-24 w-20 h-20 object-cover mr-2 mb-2  hover:shadow-lg rounded-lg" src={"https://designerblooms.com.ph/cdn/shop/files/Ecua-Pink-Deluxe_d45b3061-3c50-4ef6-ac8b-aae2159df246_240x.jpg?v=1705029851"} alt="Product Image"/>
        //    </div>
            return  <div className='flex'>{content}</div>
        } catch (error) {
            
        }
    }
    return (
        <div>
        <div class="bg-gray-100 dark:bg-gray-800 py-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col md:flex-row -mx-4">
            <div class="md:flex-1 px-4">
                <div class="h-[460px] rounded-lg dark:bg-gray-700 mb-4">
                <img
          
          src={"https://localflowershop.sgp1.digitaloceanspaces.com/product/1654912621316-paculba-new-icon.png"}
          
          class={" rounded-lg ml-124 w-20 h-20  lg:h-20 lg:w-20 opacity-70 hover:opacity-50 ease-in-out duration-300 bg-"}
          style={{position:'relative' ,top:280,left:200}}
        />
                    {currentProduct.imgUrl === '' ? '' : <img class="w-full h-full ease-in-out duration-300 object-cover rounded-lg hover:shadow-lg" src={currentImage} alt="Product Image"/>}
                  </div>
                             

                {renderSubImages()}

                
                <div class="flex -mx-2 mb-4">
                    <div class="w-1/2 px-2">
                        <button 
                        onClick={()=>addRemoveItem(currentProduct,true)}
                        
                        class="w-full bg-gray-900 dark:bg-black text-white py-2 px-4 rounded-full font-bold hover:bg-gray-800 dark:hover:bg-gray-700">Add to Cart</button>
                    </div>
                    <div class="w-1/2 px-2">
                        <button
                        onClick={()=>toast.error('Temporary unavailable')}
                        class="w-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white py-2 px-4 rounded-full font-bold hover:bg-gray-300 dark:hover:bg-gray-600">Add to Wishlist</button>
                    </div>
                </div>
            </div>
            <div class="md:flex-1 px-4">
                <h2 class="text-2xl font-bold text-gray-800 dark:text-white mb-2">{currentProduct.title}</h2>
                <p class="text-gray-600 dark:text-gray-300 text-sm mb-4 bg">
                    {currentProduct.type}
                </p>
                {/* <div class="flex mb-4">
                    <div class="mr-4">
                        <span class="font-bold text-gray-700 dark:text-gray-300">Price:</span>
                        <span class="text-gray-600 dark:text-gray-300">$29.99</span>
                    </div>
                    <div>
                        <span class="font-bold text-gray-700 dark:text-gray-300">Availability:</span>
                        <span class="text-gray-600 dark:text-gray-300">In Stock</span>
                    </div>
                </div> */}
                {/* <div class="mb-4">
                    <span class="font-bold text-gray-700 dark:text-gray-300">Select Color:</span>
                    <div class="flex items-center mt-2">
                        <button class="w-6 h-6 rounded-full bg-gray-800 dark:bg-gray-200 mr-2"></button>
                        <button class="w-6 h-6 rounded-full bg-red-500 dark:bg-red-700 mr-2"></button>
                        <button class="w-6 h-6 rounded-full bg-blue-500 dark:bg-blue-700 mr-2"></button>
                        <button class="w-6 h-6 rounded-full bg-yellow-500 dark:bg-yellow-700 mr-2"></button>
                    </div>
                </div> */}
                <div class="mb-4">
                    <span class="font-bold text-gray-700 dark:text-gray-300">Select Size:</span>
                    <div class="flex items-center mt-2 ">
                        <button   onClick={()=>toast('Temporary unavailable')} class="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 dark:hover:bg-gray-600">S</button>
                        <button   onClick={()=>toast('Temporary unavailable')} class="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 dark:hover:bg-gray-600">M</button>
                        <button   onClick={()=>toast('Temporary unavailable')} class="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 dark:hover:bg-gray-600">L</button>
                        <button   onClick={()=>toast('Temporary unavailable')} class="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 dark:hover:bg-gray-600">XL</button>
                        <button   onClick={()=>toast('Temporary unavailable')} class="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-white py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 dark:hover:bg-gray-600">XXL</button>
                    </div>
                    { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(currentProduct.price)}
                </div>
                <div>
                    <span class="font-bold text-gray-700 dark:text-gray-300">Product Description:</span>
                    <p class="text-gray-600 dark:text-gray-300 text-sm mt-2">
                    {currentProduct.subtitle}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<Toaster />
{ cart.length >= 1 ? <CartView  storeDetails={storeDetails} removeItem={(item) => addRemoveItem(item, false)} items={cart} /> : null }

{storeDetails !== null ? <Footer details={storeDetails} /> : null}
        </div>
    );
}

export default ProductDetails;