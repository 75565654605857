
import { Fragment, useState, useEffect,useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {useHistory,Switch} from "react-router-dom";
import '.././App.css';
import { Tab } from '@headlessui/react'
import PaymentOption from '../component/PaymentOption'
import {DateMPicker,BasicCalendar} from '../component/CustomCalendar'
import AddressPicker from '../component/AddressPicker'
import CartDetails from './Cart'
import { axios,productStats ,emailSender} from '../Network'

import moment from 'moment-timezone'
import toast, { Toaster } from 'react-hot-toast';
import ContentLoader from "react-content-loader"
import { nextWednesday } from 'date-fns';
const notify = () => toast('Here is your toast.');
const storeID = process.env.REACT_APP_STORE_ID
// const flux = '60b1c9a9a001ef1e463d52c2' //'Flux-Bouquet'
// const nidz = '5ff00ddaeb2f5d0940dfa186' // Dried
let peakTime = [
  {time:"February 12 2023",opening:"09:00:00",closing:"12:00:00"},
  {time:"February 13 2023",opening:"13:00:00",closing:"18:00:00"},
  {time:"February 14 2023",opening:"09:00:00",closing:"12:30:00"}
]
const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={340}
    height={84}
    viewBox="0 0 340 84"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" /> 
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" /> 
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" /> 
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" /> 
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" /> 
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" /> 
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" /> 
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
  </ContentLoader>
)
var id =  process.env.REACT_APP_STORE_ID
// https://heroicons.com/
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MyModal() {

  let [categories] = useState({
    Delivery: [
        {
            id: 1,
            title: 'Does drinking coffee make you smarter?',
            date: '5h ago',
            name: 'CustomersName',
            isRequired:true,
            title:"Customer's Name",
            placeHolder:'Juan Dela Cruz',
            commentCount: 5,
            shareCount: 2,
            state:(e)=>{
                setCustomerName(e)
            },
            value:()=>{
                return customerName
            },
            },
            {
            id: 2,
            date: '2h ago',
            name: 'MobileNumber',
            isRequired:true,
            title:"Customer's Mobile Number",
            placeHolder:"09** **** ***",
            commentCount: 3,
            shareCount: 2,
            state:(e)=>{
                setCustomerMobile(e)
            } ,value:()=>{
                return customerMobile
            }
            },{
                id: 3,
                name: 'MobileNumber',
                isRequired:true,
                title:"Receiver's Name",
                placeHolder:'e.g. Angelica Dela Cruz',
                date: 'Mar 19',
                commentCount: 24,
                shareCount: 12,  
                state:(e)=>{
                    setReceiverName(e)
                },value:()=>{
                    return receiverName
                }
            },{
            id:4,
            name: 'MobileNumber',
            isRequired:true,
            title:"Receiver's Mobile Number",
            placeHolder:"09** **** ***",
            date: 'Mar 19',
            commentCount: 24,
            shareCount: 12,
             state:(e)=>{
                setReceiverMobile(e)
            },value:()=>{
                return receiverMobile
            }
          },{
            id:4,
            name: 'CustomerEmail',
            isRequired:false,
            title:"Email",
            placeHolder:"Juan Dela Cruz",
            commentCount: 24,
            shareCount: 12,
             state:(e)=>{
              setCustomerEmail(e)
            },value:()=>{
                return customerEmail
            }
          }
          
    ],
    Pickup: [
        {
            id: 1,
            title: 'Does drinking coffee make you smarter?',
            date: '5h ago',
            name: 'CustomersName',
            isRequired:true,
            title:"Customer's Name",
            placeHolder:'Juan Dela Cruz',
            commentCount: 5,
            shareCount: 2,
            state:(e)=>{
                setCustomerName(e)
            },
            value:()=>{
                return customerName
            },
          },
          {
            id: 2,
            date: '2h ago',
            name: 'MobileNumber',
            isRequired:true,
            title:"Customer's Mobile Number",
            placeHolder:"09** **** ***",
            commentCount: 3,
            shareCount: 2,
            state:(e)=>{
                setCustomerMobile(e)
            } ,value:()=>{
                return customerMobile
            }
          },{
            id:4,
            name: 'CustomerEmail',
            isRequired:false,
            title:"Email",
            placeHolder:"Juan Dela Cruz",
            commentCount: 24,
            shareCount: 12,
             state:(e)=>{
              setCustomerEmail(e)
            },value:()=>{
                return customerEmail
            }
          }
    ]
  })

  let [isOpen, setIsOpen] = useState(true)
  const history = useHistory(); 
  const [enabled, setEnabled] = useState(false)
  const [store, setStoreDetails] = useState(null)
  const [payment, setSelectePayment] = useState(
      {
    itemName: "GCash",
    key: "xx2",
    itemDetails: "0917 843 4776",
    status: true,
    icon_url: "https://getcash.ph/wp-content/uploads/2021/01/Gcash-logo-Transparent-500x500-1.png"
  
  }
  )
  const [orderType, setOrderType] = useState(0) // 1- Delivery - 0 Pickup
  const [customerName, setCustomerName] = useState(null)
  const [customerMobile, setCustomerMobile] = useState(null)
  const [receiverName, setReceiverName] = useState(null)
  const [receiverMobile, setReceiverMobile] = useState(null)
  const [customerEmail, setCustomerEmail] = useState(null)
  const [cart, setCartItems] = useState([])
  const [notes, setNotes] = useState(null)
  const [burialNote, burialMessage] = useState('')
  const [landmark, setLandMark] = useState(null)
  const [promocode, setPromoCode] = useState('')
  const [userDate, setDate] = useState(null)
  const [orderRef,setOrderReference] = useState(null)
  const [totalGrandPrice, setTotalGrandPrice] = useState(0)
  const [isSuccess, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [storeReady, didLoadStore] = useState(false)
  const  [isValidatePromo, setValidatePromo] = useState(false);
  const  [isAgree, setDidAgree] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const  [discountedPromo, setDiscountedPromo] = useState(0); 
  const  [isPriceBelow, setPriceBelow] = useState(false); 
  const [deliveryRate, setDeliveryRate] = useState({
    price:350,
    title: "cbx-70",
    displayName: "Cebu City",
  })
  const [selectedTime, setSelectedTime] = useState("01:00 PM");
  const  [discountedDetails, setDiscountedDetails] = useState({
    "requiredAmount": 0,
    "discountedPrice": 0
  });
  
  const goBack = () => history.goBack()
  function loadProductCategory() {
    let parameter = {
      id: id,
      equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
    }
    axios
      .post("/details/Store", parameter)
      .then((response) => {
        setStoreDetails(response.data.results[0])
        didLoadStore(true)
        document.title = response.data.results[0].storeName + " Cart  - " + new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(getGrandTotalPriceV2(deliveryRate.price,true) + getTotalServiceFee())
        
      })
      .catch(function (error) {
        toast('Something went wrong, please try again')
      });
  }

  useEffect(() => {
    let existingCart = localStorage.getItem('UserCart')
    if (existingCart !== null) {
      let jsonCart = JSON.parse(existingCart)
      setCartItems(jsonCart)
    }
    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)
    if (date != null) {
      setStartDate(moment(date.date).tz("Asia/Taipei").toDate())
      setDate(moment(date.date).tz("Asia/Taipei").toDate())
    }else{
      // setStartDate(moment(newDate()).tz("Asia/Taipei").toDate())
      setDate(moment(newDate()).tz("Asia/Taipei").toDate())
    }
    loadProductCategory()
  }, [])

  
  useEffect(() => {
    
    if (startDate === null ) {
      return
    }
    
    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)
    console.log('USER DATE use effect',date)
    if (date != null) {
      // setStartDate(moment(date.date).tz("Asia/Taipei").toDate())
      setDate(moment(date.date).tz("Asia/Taipei").toDate())
    }else{
      // setStartDate(moment(newDate()).tz("Asia/Taipei").toDate())
      setDate(moment(newDate()).tz("Asia/Taipei").toDate())
    }
  }, [startDate])
  const newDate = () =>{
    var new_date = moment(new Date()).tz("Asia/Taipei");  
    return  new_date.add(1, 'days');
  }
  const  validateDetails = ()=>{
alert('s')

  }

  function closeModal() {
    setIsOpen(false)
  }
  async function recordTimeBehavior(e) {
    try {
        const data = {storeOwner:"PaculbaFlowerShop",cType:"deliveryTimeBehavior",cName:"website","userPreferredTime":e ,"date":new Date(),totalCart:getGrandTotalPrice(deliveryRate.price,true)}
        const response = await productStats.put('/items', data) 
        return  response
    } catch (error) {
    }
  }
  async function promoCodeService(e) { 
    try {  
      
      const data =   {code:e,storeOwner:id ,deliveryDate:startDate} 
       const response = await axios.post('/validate/PromoCode', data)
       return  response.data
    }catch (error) { 
      console.log(error)
        alert('something went wrong')
      }
  }
  
  function openModal() {
    setIsOpen(true)
  }

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    // this.setState({
    //     generatedID:result
    // })
    return result;
 }

 function generateListOfTime(opening,closing){
  let list = []
  let afternoonShift = "12:30:00"
  let morningShift = "13:00:00"


  
  if (moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY") === peakTime[0].time){

    let currentTime = moment(peakTime[0].opening,'hh:mm A')
    try {
      for (let i = 0; currentTime.isBefore(moment(peakTime[0].closing,'hh:mm A')); i++) {
        let convertedTime  = currentTime.add(30, 'minutes').toDate()
        let timeString =    moment(currentTime).format('hh:mm A')
        let datePickerobject =  {
          time:convertedTime,
          isSpecialHours:true,
          displayTime :timeString
        }
        list.push(datePickerobject)
      
    } 
    return list  
    } catch (error) {
      console.log('error Time covertter',error)
    }
  }else if (moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY") ===  peakTime[1].time) {
    let currentTime = moment(peakTime[1].opening,'hh:mm A')
    try {
      for (let i = 0; currentTime.isBefore(moment(peakTime[1].closing,'hh:mm A')); i++) {
        let convertedTime  = currentTime.add(30, 'minutes').toDate()
        let timeString =    moment(currentTime).format('hh:mm A')
        let datePickerobject =  {
          time:convertedTime,
          isSpecialHours:true,
          displayTime :timeString
        }
        list.push(datePickerobject)
      
    } 
    return list  
    } catch (error) {
      console.log('error Time covertter',error)
    }
  }else if (moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY") ===  peakTime[2].time) {
    let currentTime = moment(peakTime[2].opening,'hh:mm A')
    try {
      for (let i = 0; currentTime.isBefore(moment(peakTime[2].closing,'hh:mm A')); i++) {
        let convertedTime  = currentTime.add(30, 'minutes').toDate()
        let timeString =    moment(currentTime).format('hh:mm A')
        let datePickerobject =  {
          time:convertedTime,
          isSpecialHours:true,
          displayTime :timeString
        }
        list.push(datePickerobject)
      
    } 
    return list  
    } catch (error) {
      console.log('error Time covertter',error)
    }
  } else {

  let currentTime = moment(opening,'hh:mm A')
  try {
    for (let i = 0; currentTime.isBefore(moment(closing,'hh:mm A')); i++) {
      let convertedTime  = currentTime.add(30, 'minutes').toDate()
      let timeString =    moment(currentTime).format('hh:mm A')
      let datePickerobject =  {
        time:convertedTime,
        isSpecialHours:true,
        displayTime :timeString
      }
      list.push(datePickerobject)
    
  } 
  return list  
  } catch (error) {
    console.log('error Time covertter',error)
  }
  }
  
}

const selectedTimeServiceLogger=(time)=>{
  recordTimeBehavior(time)
  setSelectedTime(time)
}
const renderTime = (item)=>{
  return (
    <button
    onClick={()=>selectedTimeServiceLogger(item)}
    
    type="button"
    className= {selectedTime  === item ?  "bg-black  inline-flex hover:scroll-auto  hover:shadow-lg border-black justify-center px-4 py-2 text-sm font-medium ml-4 text-white border border-transparent rounded-full ease-in-out duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  :" focus:ring-indigo-500 inline-flex mb-2 hover:bg-black ease-in-out duration-300   justify-center px-4 py-2 text-sm font-medium ml-4 text-gray-600 hover:text-white bg-gray-100 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  }
    >  
  {true ? <> <div className=""><span className="font-sm " >{item}</span></div>
  {'category' !== 'item.title'  ? <div className=""><span className="font-sm ml" ></span></div> : <div className="">
   <svg version="1.1" id="loader-1" className="animate-spin h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" space="preserve">
   <path opacity="0.2" fill="#FFFF" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
     s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
     c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
   <path fill="#FFFF" className="border-white border-2" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
     C22.32,8.481,24.301,9.057,26.013,10.047z">
     </path>
   </svg>
 </div> }
  </>: <><div className=""><span className="font-sm " >{'item.displayName'} +</span></div>
  <a href="javascript:void(0);" >
  {/* <svg xmlns="http://www.w3.org/2000/svg" className="lg:ml-2 md:ml-2 h-5 w-5" fill="none" viewBox="0 0 20 20" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
</svg> */}
    </a> </>}
    
  </button>
  )
}
function renderListTime(){
   
  try{

    if (store === null){
      return <MyLoader/>
    }
    let timeZ = store.storeOptions.businessHours
    let openingTime  = timeZ.opening
    let closeTime  = timeZ.closing
    let convertedTime =  moment(openingTime,'hh:mm A').add(130, 'minutes').toDate()
    let listOfConverterTime = generateListOfTime(moment(openingTime,'hh:mm A').toDate(),moment(closeTime,'hh:mm A').toDate())
    let list = []//generateListOfTime(moment(openingTime,'hh:mm A').toDate(),moment(closeTime,'hh:mm A').toDate())
    let time = []
    list = listOfConverterTime
    list.map(item =>{
      time.push(renderTime(item.displayTime))
    })

  
    if (peakTime.some(item => item.time === moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY")) ){
      return null
    }else {
      return time
    }
    
  }catch(error){
    return <MyLoader/>
  }
}
 function successContent(e){
    let path = `/`;
    setOrderReference(e)
    setSuccess(true)
    localStorage.removeItem('UserCart')
    localStorage.removeItem('userQ')
 }
 const generateFormmatedDate=()=>{
  return `${moment(startDate).tz("Asia/Taipei").format("MMMM Do YYYY")} at ${selectedTime}`
 }
 async function notifier(payload){
    try {
      let emailData = payload.data.results
      console.log('payload',emailData)
      emailData.subject = `Your Paculba Flowershop Online Order#:${emailData.orderReference} (ON HOLD)`
      emailData.headLine = "Paculba Order Online"
      emailData.storeLogo = "https://localflowershop.sgp1.digitaloceanspaces.com/product/1654912621316-paculba-new-icon.png"
      emailData.storeName = "paculbaflowershop"
      return  await emailSender.post('/notifier', emailData)
    }catch(error){
      console.log('notifier errror',error)
      return null
    }
 }
 async function placeOrderState(){

  
   try {  
    let id = makeid(10)  
     let response = await axios.put('/OrderV2',{
          storeOwner:storeID,
          cType:"Order",
          cName:"Items",
           cart:cart,
           reference: id,
           resellersID: 'null',
           details: {
               customerName:customerName,
               contact:'Email',
               mobile:customerMobile,
               Receiver:receiverName,
               ReceiverContactNumber:receiverMobile,
               StreetAddress:`${landmark}, ${deliveryRate.displayName}`,
               orderType:orderType === 1 ? 'Store-Pickup':'Delivery',
               selectedDate:userDate,
               formattedDate: generateFormmatedDate(),//moment(userDate).tz("Asia/Taipei").format('MMMM Do YYYY, h:mm:ss a'),
               deliveryNotes:notes,
               burialMessage:burialNote,
               deliveryRateDetails:deliveryRate,
               email:customerEmail
           },
           storeDetails:store.storeContact,
           storeWebsite:store.Website,
           tracker:store.storeOptions.tracker,
           storeCodeName:store.codeName,
           payment:payment,
           total:getGrandTotalPriceV2(deliveryRate.price,true) + getTotalServiceFee(),
           code:promocode === '' ? "NONE" : promocode 
       })
 
       return response
       
        
   } catch(error){
    console.log('error place order',error)
return null
   }
 }
 const  validateAddons = useMemo(()=> {
  var required = 1
  let allowed =  cart.filter((item: { type: string; }) =>   item.type != "Food" && item.type != "Tart-Addons" && item.type != "Gifts" )
  return  allowed.length  >= required ? true : false
},[cart])

 function validateBurial(){ 
  var isFound =  cart.filter ( item =>   item.type === "Funerals")?.length

  return  isFound ?   <>
  <label className=" block  ml-3 tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
Funeral Message
               </label>
<textarea  onChange={(e)=>burialMessage(e.nativeEvent.target.value)} className=" w-80 px-3 py-2  ml-3 text-sm   bg-gray-100  text-gray-700 border rounded-lg focus:outline-none" placeholder="E.g. In loving memory..., Forever in our thoughts..." rows="4"/>

  </> :  null
 }
  function processOrder(e) {
   
    
   if ( orderType === 0 && (receiverName === null || receiverMobile === null || customerMobile === null || customerName === null || landmark === null) ) {
  
          toast('Please fill the required delivery details')  
          return  
    }else if ( orderType === 1 &&  (customerMobile === null || customerName === null)) {
        toast('Please fill the required pickup details')  
        return  
    } else if (userDate === null) {
      toast(`Please add ${orderType === 0 ? 'delivery ' : 'pickup '}date & time`)  
      return  
    }
    setLoading(true)
    try { 

      placeOrderState().then((res) =>{ 
        if (res != null) {
          notifier(res).then((email)=>{
            successContent(res.data)
          })
        }else {
          setLoading(false)
          alert('Please try again..')
        }
      
    })  
    }catch (error) {
        alert('error')
      
    }
 
    }
  function getGrandTotalPrice (df,isGrandTotal){ 
    let initialPrice = 0
    try {
        var getTotalflatRate = orderType === 1 ? 0 : df
        cart.map(data => {  initialPrice += data.price * 1  }) 
        let service =  getTotalflatRate 
        return  isGrandTotal ?new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(initialPrice + Number(service) - getFinalPromo())  : new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(initialPrice + Number(service))
    } catch(error) { 
        toast('Something went wrong, please try again')
        return 0
    } 
}

function getTotalServiceFee(){
  return payment.key === "xx3" ? getGrandTotalPriceV2(deliveryRate.price ,true)  * .10 : 0
}

function getGrandTotalPriceV2 (df,isGrandTotal){ 
  let initialPrice = 0
  try {
      var getTotalflatRate = orderType === 1 ? 0 : df
      cart.map(data => {  initialPrice += data.price * 1  }) 
      let service =  getTotalflatRate 
      return   initialPrice + Number(service) - getFinalPromo()
      
  }catch(error) { 
      // alert('error')
       
      toast('Something went wrong, please try again')
      // setTotalGrandPrice(0)
      return 0
  } 
}
function getGrandTotalPriceInt (df){ 
  let initialPrice = 0
  try {
      var getTotalflatRate = orderType === 1 ? 0 : df
      cart.map(data => {  initialPrice += data.price * 1  }) 
      let service =  getTotalflatRate 
      return initialPrice  + Number(service)
      
  }catch(error) { 
      // alert('error')
      toast('Something went wrong, please try again')
      // setTotalGrandPrice(0)
      return 0
  } 
}

  const toggleView = ()=> {
      return (
        <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? 'bg-blue-600' : 'bg-gray-200'
        } relative inline-flex items-center h-6 rounded-full w-11`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>
      )
  }

function displayDF(){
    return (
        <h3 className="text-xs    ml-6 text-left   font-xs  leading-5">
      <span className="text-gray-900  ">   Delivery Fee: </span>             { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(deliveryRate.price) }
       </h3>
    )  
}
function creditCardFee(){
  if (payment.key === "xx3"){
  return (
      <h3 className="text-xs mt-4  ml-6 text-left   font-xs  leading-5">
    <span className="text-gray-900  ">   Service Charge: </span>        {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(getTotalServiceFee()) }
     </h3>
  )  
}
}
const displayColorBG =()=>{
  try {
    return store.storeOptions.colorTheme
  }catch{
    
  return 'white'
  }
}
const updateOrderType = (e)=> {
  setOrderType(e)

  setCustomerName(null)
  setCustomerMobile(null)
  setReceiverName(null)
  setReceiverMobile(null) 
}
const displayPaymongoContent = ()=>{
  return (<>

<div className="w-full bg-black mt-10 mb-5" style={{height:0.5,opacity:0.2}}/>
  <h3 className="   mt-10 text-gray-700 text-xs font-xs font-light  leading-5">
  Pay using our Paymongo Link <br/><a href={orderRef.results.paymongoLink} target='_blank'><span className="font-bold text-blue-600">{orderRef.results.paymongoLink}</span></a>
</h3>  

<a href={'https://paymongo.com/'} target='_blank'>
<img src="https://pm.link/static/media/powered_by.b537e1c4.png"  className="w-auto h-10 mt-2 mb-20 "/>
</a>
  </>)
}
const successOrder = () => {
  var dateToday = new Date()
  return  (
    <>
     <div className="min-h-screen px-4 text-center"> 
        <span
         className="inline-block h-screen align-middle"
         aria-hidden="true"
       >
         &#8203;
       </span>
   
         <div className="inline-block w-full max-w-md p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl  overflow-auto" style={{height:'auto' ,overflow:'auto'}}>
             {/* <h2 className="text-xl    p-6 font-medium">
          Order has been placed.
        
         </h2> */}
         <h2 className="text-xl  text-black  sm:text-center  lg:text-center sticky margin: 20rem my-10  p-2 font-medium">
         Your order has been placed!
         </h2>

         {/* <label className=" block ml-6 tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
       Your order has been placed!
       </label>  */}
         <div/>
       
       
      

         <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-5 ">
         <img src="https://gatesbbq.com/wp-content/uploads/2017/04/checkmarksuccess.gif"  className="w-auto h-auto"/>
         

         <div className=" sm:text-center  lg:text-center sticky margin: 20rem my-10 text-4xl tracking-tight content-center   text-gray-900 sm:text-2xl md:text-6xl mb-90 font-bold">   

         {orderRef.results.orderReference} 
         <label className=" block tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
      Reference Code
       </label> 
       
</div> 
<div class="bg-white  text-center py-4 lg:px-4" style={{alignContent:'center',justifyContent:'center',alignItems:'center'}}>
  <div class="p-2 bg-red-100 items-center text-indigo-100 leading-none rounded-full  lg:rounded-full flex lg:inline-flex" role="alert">
    <span class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs  text-white font-bold mr-3">Status</span>
    <span class="font-semibold mr-2 text-left text-yellow-900 flex-auto">Payment Required</span>
    <svg class="fill-current opacity-75 h-4 w-4 text-yellow-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" /></svg>
  </div>
  <div class="p-2 font-semibold ">Amount due : { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(orderRef.results.totalPrice)  }</div>
  
</div>
<h3 className="   text-gray-700 text-xs font-xs font-light  mb-2  leading-5">
             Selected Payment Method  <br/>
             {" Bank Details : Nidalyn Paculba"}
   </h3>

{storeReady ? <PaymentOption isItinerary={true} selectedPayment={payment.itemName} details={store} selectePayment={(e)=>setSelectePayment(e)}/>: <MyLoader/> }

{/* <PaymentOption  isItinerary={false} details={store} selectePayment={(e)=>setSelectePayment(e)}/> */}
 
{ orderRef.results.paymentDetails.key === "xx3" ? displayPaymongoContent() : null}

       <div className="w-full bg-black mt-10 mb-5" style={{height:0.5,opacity:0.2}}/>
     <label className=" block  justify-center  mt-5 tracking-wide  text-gray-700 text-xs font-light mb-2" for="grid-first-name"> 
     Your booking will be put on hold until <span class="font-semibold mr-2 text-left text-blue-900 flex-auto">{getProperTyime()}.</span>Please make payment to avoid your booking being automatically  <span class="font-bold mr-2 text-left text-red-500 flex-auto uppercase">cancelled</span>. 
    </label>
      
             {/* <div class="bg-white  text-center py-4 lg:px-4" style={{alignContent:'center',justifyContent:'center',alignItems:'center'}}>
  <div class="p-2 bg-red-100 items-center text-indigo-100 leading-none rounded-full  lg:rounded-full flex lg:inline-flex" role="alert">
    <span class="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs  text-white font-bold mr-3">Status</span>
    <span class="font-semibold mr-2 text-left text-yellow-900 flex-auto">Payment Required</span>
    <svg class="fill-current opacity-75 h-4 w-4 text-yellow-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" /></svg>
  </div>
  <div class="p-2 font-semibold ">Amount due : ₱200.00</div>
  
</div> */}
 <div>
 <div className="mt-5  text-gray-700 ">What to do next?
 <ul class="list-none md:list-disc font-light text-xs ml-5  text-gray-700 ">
  <li>Pay using your selected <span class="font-semibold mr-2 text-left text-blue-900 flex-auto">Payment Method.</span></li>
  <li>Then upload screenshot using the link below</li>

</ul>
 </div>
 <button
             disabled={cart.length  !== 0 ? false : true}
               type="button"
               className= {"inline-flex  w-full justify-center mt-5 px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"   }
               onClick={()=>window.open(`${store.storeOptions.tracker}/${orderRef.results.orderReference}`, "_blank")}
             >
              Upload your proof of payment here
             <a href="/Cart" >
               </a>
             </button>

 </div>
    
    
{/* <button
             disabled={cart.length  !== 0 ? false : true}
               type="button"
               className= {"inline-flex  justify-center mt-20 px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"   }
               onClick={()=>history.push('/')}
             >
               Back to Store
             <a href="/Cart" ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
               </svg></a>
             </button> */}



        {/* <button

        type="button"
        className="inline-flex ml-4 w-100 justify-center px-4 py-2 text-sm font-medium   text-black bg-gray-100 border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        onClick={goBack}
        >

        Save Order
        <a href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
        </svg></a>
        </button> */}
{/* 
             <label className=" block   mt-2 tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
             Client has an option to book their own delivery service. However, for any damages or losses, FLC will not responsible *
     
             </label> */}

        </div>  
        {/* <div class="px-6 pt-4 pb-2">
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#facebook</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#ins</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
  </div> */}
         </div>
         
         </div>

    </>
  )
}
function getTotalItemPerCategory(e){

var initialPrice  = 0 
 cart.map(data => {  
   
  if (data.subCategory == e || data.type == e) {
    initialPrice += data.price * 1
  }
  }) 
  
  return  initialPrice
}

function getFinalPromo(){
    var  total  = 0 
    if (discountedDetails.discountedPrice === 0 ) {
      return 0
    }else {
      if (discountedDetails.isPercentage){
        var totalOrder = getTotalItemPerCategory(discountedDetails.requiredCategory[0])
        total = totalOrder  >= discountedDetails.requiredAmount ? (discountedDetails.discountedPrice / 100 )*totalOrder : 0
        return total
        }else {
          var totalOrder = getTotalItemPerCategory("Flux-Bouquet")
          
          total = totalOrder  >= discountedDetails.requiredAmount ?  discountedDetails.discountedPrice  : 0
          return total
          // var totalOrder =getTotalItemPerCategory("Flux-Bouquet") //getGrandTotalPriceInt(0)  
          // total = getGrandTotalPriceInt(0)   >= discountedDetails.requiredAmount ? totalOrder : 0
          // console.log('DISCOUNTED',total)
          return totalOrder
    
      }
    }
  
}
function renderOrderMessage(){
  var messages = ["Other areas not on the list will be charged accordingly depending on the distance *","Client has an option to book their own delivery service. However, for any damages or losses, Our shop will not be responsible. *"]
  var contents = []
    messages.map ( item => {
      contents.push(
        <label className=" block   mt-2 tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
          {item}
          </label>
      )
    } )
    return contents
}
function submitPromoCode(){
  var promocodeReference =  promocode

  if (promocodeReference === '' || promocodeReference === null) {
    return
  }
  setValidatePromo(true)
  
  promoCodeService(promocode).then( item => {
      setValidatePromo(false)
      if (item.result.isValid) {
        var promoCodeType = item.result.promoDetails
          setDiscountedDetails(item.result.promoDetails) 
            
       var  total  = getGrandTotalPriceInt(0) // finalPrices  >= item.result.promoDetails.requiredAmount ? item.result.promoDetails.discountedPrice : 0 
       var totalOrder = getTotalItemPerCategory(promoCodeType.requiredCategory[0])
          total = totalOrder  >= promoCodeType.requiredAmount ? (promoCodeType.discountedPrice / 100 )*totalOrder : 0
          if ( total == 0) {
            setPromoCode('')
            toast(`[${promocodeReference}] Sorry! ${item.result.promoDetails.message}`)
          }else {
            toast(`${promocodeReference} has been applied `)
          }
      }else { 
        setPromoCode('')
        toast(`[${promocodeReference}] Sorry, your code is invalid.`)
      }
  })
}
const inProgressOrder = () => { 
  return (

    <>
     <div className="min-h-screen px-4 mb-20  text-center"> 

        <span
         className="inline-block h-screen align-middle"
         aria-hidden="true"
       >     
         &#8203;
       </span>
       <Toaster />
         <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl  overflow-auto" style={{height:'auto' ,overflow:'auto'}}>
            
             <h2 className="text-xl    p-6 font-medium">
          Order  {orderType === 0  ? "Delivery": "Pick-up"}
         </h2>
        {store != null ? <img src={store.storeLogo} className="h-12 w-12 mx-2  absolute top-10 hover:shadow-lg right-20" />  : null}
          {/* <span className="ml-5 text-lg ">{moment(startDate).format("MMMM DD YYYY")}</span> */}
         <div className="w-full max-w-md px-2 py-2 sm:px-0">
         
 <Tab.Group 
 onChange={(e)=>updateOrderType(e)}>
   <Tab.List className="flex p-1  space-x-1 bg-white-900/20 rounded-xl">
     {Object.keys(categories).map((category) => (
       <Tab
         key={category}
         
         className={({ selected }) =>
           classNames(
             'w-full py-2.5 text-sm leading-5  font-medium text-black  ring-black rounded-lg',
             'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-black  ring-white  text-black ring-opacity-60',
             selected
               ? 'bg-black shadow text-white'
               : 'text-black hover:bg-black/[0.9] hover:text-gray-400'
           )
         }
       > {category}
       </Tab>
     ))}
   </Tab.List>
   <Tab.Panels className="mt-2">

     {Object.values(categories).map((posts, idx) => (
         
       <Tab.Panel
         key={idx}
         className={classNames(
           'bg-white rounded-xl p-3',
           'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-white-400 ring-white ring-opacity-60'
         )}
       >    {posts.map((post) => (
               <>
               <div className=" w-full md:w-full px-3 mb-6 md:mb-0">
               <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                   {post.title} <span className="text-red-400">*</span>
               </label>
               <input key={post.id} value={post.value()} onChange={(e)=> post.state(e.nativeEvent.target.value)} placeHolder={post.placeHolder} className={"appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"} id="grid-first-name" type="text"/>
               
           </div> </>
           ))}


<label className=" block  ml-3 tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
Order Notes / Special Instruction (optional)
               </label>
<textarea  onChange={(e)=>setNotes(e.nativeEvent.target.value)} className=" w-80 px-3 py-2  ml-3 text-sm   bg-gray-100  text-gray-700 border rounded-lg focus:outline-none" placeholder="Type it here" rows="4"/>

{store === null ? null : orderType === 0 ? 
        

        <>
        {validateBurial()}
        <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-5 ">
          
        <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
       Address
       </label> 
       {storeReady ?  <AddressPicker className="z-50 " selected={(e)=>setDeliveryRate(e)} details={store}/> : <MyLoader/>}


             
       </div>  
       <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-5 ">
        
          <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
         Location Landmark<span className="text-red-400 ml-1">*</span>
         </label> 
         <input value={landmark}  onChange={(e) => setLandMark(e.nativeEvent.target.value)}placeHolder="e.g. Bldg 2nd floor,Juana Street " className={"  text-xs appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"} id="grid-first-name" type="text"/>
         <div className="w-full bg-black mt-10 mb-5" style={{height:0.5,opacity:0.2}}/>
     


{renderOrderMessage()}
               {/* <label className=" block   mt-2 tracking-wide text-gray-700 text-xs font-light mb-2" for="grid-first-name">
               Client has an option to book their own delivery service. However, for any damages or losses, Paculba Flowershop will not responsible *
                </label> */}

          </div>  

       </>
       
       
       : null  } 
    
     

             
      






               {/* <div className=" w-full mt-2 p-6  text-left align-middle transition-all transform " >
                 <div className="flex-col ... "> 
             <h3 className="text-sm   font-medium leading-5 ">
             Upload Video Message 
         </h3>
         </div>
         <div className="w-full  py-2 sm:px-0">
<img src="https://cdn.dribbble.com/users/767646/screenshots/1940258/cam.gif" className="h-20 w-auto"/>
            </div>  
            

            <span className="text  italic text-xs">Your video will attached to your flowers and scan by qrcode.  <br/></span>

            <button
            type='file' 
             disabled={cart.length  !== 0 ? false : true}
               type="button"
               className= {cart.length  !== 0 ? "inline-flex  justify-center px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" :"inline-flex  mt-2 justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-gray-200 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  }
               onClick={()=>console.log(null)}
             >
               Select Video
             <a href="/Cart" ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
               </svg></a>
               <input type='file' class="hidden" />
             </button>

             <div className="flex items-center justify-between w-full ">
             <div className="flex items-center">
               <div className="text-sm"> 
           
       </div>
       </div>
       </div>
           </div> */}

<div className=" w-full md:w-full px-3 mb-6 mt-10 md:mb-0">
<h3 className="   text-black text-xs font-bold  flex leading-5">
     {orderType === 0  ? "Delivery": "Pick-up"} Date and Time<img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 mt-1" /> <span className="text-red-400 ">*</span>
         </h3>
               <label className=" block tracking-wide text-blue-400 text-xs font-md mt-2 mb-0" for="grid-first-name">
               {/* {moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY")} */}
               {moment(startDate).tz("Asia/Taipei").format("MMMM D, YYYY")}  {peakTime.some(item => item.time === moment(startDate).tz("Asia/Taipei").format("MMMM D YYYY")) ?  '(Within the day)' : `at ${selectedTime}` } 
               </label>
               
             <div className='mt-2'/>
             {renderListTime()}
               {/* <DateMPicker selected={(e)=> setStartDate(e)}/> */}
               </div>
         
         <div className="inline-block w-full  p-6 my-8 text-left align-middle transition-all transform " >
             <h3 className="   text-black text-xs font-bold   leading-5">
             Payment Method - {payment.itemName}
         </h3>
         <div className="w-full  py-2 sm:px-0">

           {store === null ?null :<PaymentOption  isItinerary={false} details={store} selectePayment={(e)=>setSelectePayment(e)}/> }
           </div>  

           </div>
            

           <div style={{width:'auto',height:1,backgroundColor:'gray',opacity:0.5,marginTop:10}}/>
         

           <div className="inline-block w-full  p-6 my-1 text-left align-middle transition-all transform " >
             <h3 className="ext-black text-xs font-bold    leading-5">
             Order Summary 
         </h3>
         <div className="mt-2 bar overflow-auto" style={{height:  'auto',overflow:'auto'}}>
         <CartDetails items={cart} removeItem={(e)=>console.log(e)} showDelete={false}/>
           </div>
         </div>


      
       

         <div className="inline-block w-full  p-6 my-1 text-left align-middle transition-all transform " >
             <h3 className="ext-black text-xs font-bold    leading-5">
             Apply Promotion 
         </h3>
         
         <div className="mt-2 bar  overflow-auto" style={{height:  'auto',overflow:'auto'}}>
         <input disabled={getFinalPromo() != 0  ? true: false} value={promocode}  onChange={(e) => setPromoCode(e.nativeEvent.target.value)}placeHolder="Enter code " className={"w-1/2  text-xs appearance-none blockbg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"} id="grid-first-name" type="text"/>
         
         <button  disabled={promocode === '' ? true :  getFinalPromo() != 0 ? true:false}  onClick={()=>submitPromoCode()}  class= {isValidatePromo ? " font-md    justify-center font-medium  ml-0 lg:ml-4 text-white bg-gray-700 border border-transparent  text-sm py-2  rounded-full inline-flex items-center"  : getFinalPromo() != 0 ? "font-md    justify-center  font-medium ml-4 text-black bg-gray-200 border border-transparent  text-sm py-2 px-2 rounded-full inline-flex items-center text-white"  :" font-md    justify-center  font-medium ml-4 text-white bg-black border border-transparent  text-sm py-2 px-4 rounded-full inline-flex items-center"}
         style={{backgroundColor:displayColorBG().buttonColor}}
         >
         <span  style={{opacity:isValidatePromo ? 0.2:  getFinalPromo() != 0 ? 1: 1}}>{getFinalPromo() !=  0 ? 'Code Applied' : 'Apply Code '}</span>
        
         {getFinalPromo() != 0 ? 
           <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-0" fill={'green'} viewBox="0 0 24 24" stroke='green'>
         <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
               </svg> :  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{opacity:isValidatePromo ? 0.3 : 1}}>
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
</svg>}
      
        </button>
           </div>
         </div>
       <h3 className="text-xs  mb-5  ml-6 text-left   font-xs  leading-5">
               <span className="text-gray-900 text-xs font-md ">  Subtotal : </span>  {getGrandTotalPrice(0)}
       </h3>
       {orderType === 0 ?  displayDF() : null}
       {creditCardFee()}
       {getFinalPromo() === 0 ? null :     <h3 className="text-xs   mt-5 ml-6 text-left   font-bold  leading-5">
     
               <span className="text-gray-900 text-xs font-md ">  Promo Code : </span> <span className="text-red-500 ">- {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(getFinalPromo()) }</span> 
       </h3> } 
   
         <div className="inline-block w-full    p-6 text-left align-middle transition-all transform " >
             <h3 className="text-md font-bold leading-5">
             <span className="text-gray-900 font-md ">  Grand Total: </span> <span className="text-green-600 font-md ">{getGrandTotalPrice(deliveryRate.price + getTotalServiceFee(),true)}</span>
         </h3>
         </div>
          <div className="bg-gray-500  w-full " style={{height:0.2}}/>
         {/* <div class="flex mt-6">
                <label class="flex items-center">
                  <input type="checkbox" id="agree" onChange={(e) => setDidAgree(!isAgree)} class="form-checkbox" />
               
               
                 <span  class="ml-2">I agree of the <a  class="underline" href="/TermsAndCondition">Terms & Condition</a></span>

                </label>
              </div> */}
</Tab.Panel>
     ))}
   </Tab.Panels>
 </Tab.Group>
</div>
          
            

            
          
           <div className="mt-4 ">
        

           {validateAddons ? null :<div
className="mb-4"
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
            <p class="text-red-500 text-xs ">  FLOWERS REQUIRED</p>  
                     <p category="c1"style={{marginTop:10}}>Sorry, you can't purchase without flowers.</p>
                    </div> }
             <button
               type="button"
               className="inline-flex  w-100 justify-center px-4 py-2 text-sm font-medium   text-black bg-gray-100 border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
               onClick={goBack}
             >Cancel
             <a href="/" ><svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
               </svg></a>
             </button>
    {validateAddons ? isLoading ?   <button type="button" class="bg-rose-600 ..." disabled>
  <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
  </svg>
  Processing
</button> :    
<button
               disabled={cart.length  !== 0 ? false : true}
               type="button"
               className= {cart.length  !== 0 ?  "inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white   border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" :"inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-gray-200 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  }
               style={{opacity: isAgree ? 1:0.5,
                      backgroundColor:displayColorBG().buttonColor
                    }}
               onClick={()=>processOrder(null)}
             >  
              Place order 
             <a href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
               </svg></a>
             </button>  : null}       

           </div>
         </div> 
     </div>
    </>
  )
}

var getProperTyime = ()=>{
  var dateToday = new Date().toString()
  var date = moment(dateToday).tz("Asia/Taipei")
    .add(120, 'minutes')
    .format('LLL [(TODAY)] ');
  return date
  // return  moment(dateToday).toDate()
}
  return (
    <>
     {isSuccess ?   successOrder() :inProgressOrder() }
    </>
    
  )
} 