import { Fragment, useState,useRef,useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { Switch,Popover } from '@headlessui/react'
import { BeakerIcon ,ShoppingCartIcon} from '@heroicons/react/solid'
import Footer from './component/Footer'
import LandingHeader from './component/LandingHeader'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import ReactMapboxGl, { Layer, Feature,Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { url,axios } from './Network'
import toast, { Toaster } from 'react-hot-toast';
import ContentLoader from "react-content-loader"
mapboxgl.accessToken = 'pk.eyJ1IjoibWFtbmlkeiIsImEiOiJjanZsNnhhZ24wdDE1NDlwYmRvczJzNDk2In0.Bl06Qp0TgR-KfisAsKbciQ';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoibWFtbmlkeiIsImEiOiJjanZsNnhhZ24wdDE1NDlwYmRvczJzNDk2In0.Bl06Qp0TgR-KfisAsKbciQ'
}); 

var id =   process.env.REACT_APP_STORE_ID
const MyLoader = (props) => (
  <ContentLoader viewBox="0 0 400 31" height={200} width={'auto'} {...props}>
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="5" />
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="15" />
        <circle cx="388" cy="12" r="12" />
        <rect height="24" rx="0" ry="0" width="24" x="0" y="0" />
      </ContentLoader>
  )
export default function ContactForm(props) {
  const [open, setOpen] = useState(true) 
  let [isOpen, setIsOpen] = useState(true)
  let [name, setName] = useState('')
  let [emailNumber, setContact] = useState('')
  let [message, setMessage] = useState('')
  const [enabled, setEnabled] = useState(false)
  var product = props.item
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(123.89234);
  const [lat, setLat] = useState(10.31122);
  const [zoom, setZoom] = useState(20);
  const [isSend, setSend] = useState(false);
  const [isAllow, setAllow] = useState(false);

  let [didLoad, didLoadStore] = useState(false)
  let [store, setStoreDetails] = useState(null)
  useEffect(() => {
    if (store === null) {
      let parameter = {
        id: id,
        equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
      }
      axios
      .post("/details/Store", parameter)
      .then((response) => {
        setStoreDetails(response.data.results[0])
        didLoadStore(true)
        
      })
      .catch(function (error) {
        toast('Something went wrong, please try again')
      });
    }

      setAllow( (emailNumber.length >= 1 &&  name.length >= 1  && message.length >= 1 )  ? true : false)
    },[isAllow,emailNumber,message,name,isSend]);
    const ll = new mapboxgl.LngLat(lng, lat);

 
    const sendMessage=()=>{
      sendService()
      
    }
    const sendService = () => {
      let parameter = {
        id: 'st',
        payload: {
          name:name,
          contact:emailNumber,
          message:message,
          storeNumber:store.storeContact
        }
      }

      axios
      .post("/support", parameter)
      .then((response) => {
        setSend(true)
        setName('')
        setContact('')
        setMessage('')
      })
      .catch(function (error) {
        console.log('fetching product', error)
      });

    }
    function contactForm(){
        return(
            
 <div class="m-10">




<div  className=" bg-auto bg-no-repeat bg-center  " />

<div class="shadow-lg flex flex-col-reverse sm:flex-row bg-auto bg-no-repeat bg-center  rounded-t bg-scroll         lg:ml-20 mr-20">
   
<img
                        className="h-150 g:text-center-1 w-auto mr-20 sticky "
                        src="https://images.unsplash.com/photo-1503278501277-e50457741130?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2250&q=80"
                      /> 

   </div>


<div  className=" bg-auto bg-no-repeat bg-center  " />


{/* <section class="text-gray-600 body-font relative">
  <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
      <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=%C4%B0zmir+(My%20Business%20Name)&ie=UTF8&t=&z=14&iwloc=B&output=embed" ></iframe>
      <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
        <div class="lg:w-1/2 px-6">
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
          <p class="mt-1">Baseline Residences, Juana Osmeña Street, Cebu City</p>
        </div>
        <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
          <a class="text-indigo-500 leading-relaxed">theflowerluxe.cebu@gmail.com</a>
          <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
          <p class="leading-relaxed">123-456-7890</p>
        </div>
      </div>
    </div>
    <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <h2 class="text-gray-900 text-lg mb-1 font-extralight title-font">Feedback</h2>
      <p class="leading-relaxed mb-5 text-gray-600">We’re happy to answer any questions you have or provide you with an estimate. Just send us a message in the contact form with any questions you have.</p>
      <div class="relative mb-4">
        <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
        <input type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
        <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
        <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button class="text-white bg-black border-0 py-2 px-6 focus:outline-none hover:bg-black rounded text-lg">Button</button>
      <p class="text-xs text-gray-500 mt-3">Chicharrones blog helvetica normcore iceland tousled brook viral artisan.</p>
    </div>
  </div>
</section> */}











<div class="shadow-lg flex flex-col-reverse sm:flex-row            lg:ml-20 mr-20">
    
    <div class="w-full bg-white p-4">
    <h6 className="uppercase sm:text-center  lg:text-left-1 sticky margin: 20rem my-10 text-4xl tracking-tight content-center   text-gray-900 sm:text-2xl md:text-6xl mb-90 font-extralight">   

Contact us
</h6>  
<p className="mt-3 text-base text-gray-500 mb-2 text-center m-10"> 

We’re happy to answer any questions you have or provide you with an estimate. Just send us a message in the contact form with any questions you have.

</p>
      <div class="text-gray-700 mb-8">
   
       </div>


       <div class="">
        <div>
          <span class="uppercase text-sm text-gray-600 font-light">Full Name</span>
          <input 
          onChange={(e)=>setName(e.nativeEvent.target.value)}
          class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text" placeholder=""/>
        </div>
        <div class="mt-8">
          <span class="uppercase text-sm text-gray-600 font-light">Email/Mobile Number</span>
          <input 
          onChange={(e)=>setContact(e.nativeEvent.target.value)}
          class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"/>
        </div>
        <div class="mt-8">
          <span class="uppercase text-sm text-gray-600 font-light">Message</span>
          <textarea

          onChange={(e)=>setMessage(e.nativeEvent.target.value)}
            class="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
        </div>
        <div class="mt-8">
        
          {isSend ?     <button 
          onClick={()=>setSend(false)}
          className= "w-auto flex uppercase items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text- bg-green   md:py-4 md:text-lg md:px-10" 
           >
             
                  <span class="uppercase text-sm text-black font-light">    Send Again</span>
        
          </button> :    <button 
          disabled={!isAllow}
          onClick={sendMessage}
          className= {isAllow ?  "w-auto flex uppercase items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-gray-900 md:py-4 md:text-lg md:px-10" :  "w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-500 hover:bg-gray-500 " }
          
         
           
            >
                  <span class="uppercase text-sm text-white font-light">    Send Message</span>
        
          </button> }
        {isSend ? <div className =" flex flex-row text-center "><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /> </svg> <p class="text-gray-600 text-xs text-center ">Thank you! Message has been sent </p>  </div>: null}  
       
        </div>
      </div>

    </div>




    <div class="w-full bg-white p-4 visible lg:visible sm:invisible text-white text-center flex flex-col justify-center ">





<div className="mapouter w-auto"><div className="gmap_canvas w-auto ml-20"><iframe width={'100%'} height={'100%'} id="gmap_canvas" src="https://maps.google.com/maps?q=Plazaa%20alemania,%20,%20Iligan  %20City&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://www.zipcodewiki.net/united-states/">zipcodewiki.net</a><br /><style dangerouslySetInnerHTML={{__html: ".mapouter{position:relative;text-align:right;height:500px;width:600px;}" }} /><a href="https://www.embedgooglemap.net" /><style dangerouslySetInnerHTML={{__html: ` .gmap_canvas {overflow:hidden;background:none!important;height:500px;width:${'100%'};} `}} /></div></div>
 <p className="mt-3 text-base  text-gray-500 mb-2 text-center m-10"> 

 In front of Plaza Alemania Hotel, Quezon Avenue Extension, Iligan City

</p>
    </div>
</div>







    </div>
        )
    }


    function validationContent(){
        return contactForm() // isSend ? <></> : contactForm()
    }
  return (
      <>
      
    {validationContent()}
 
    {store !== null ? <Footer  details={store} />:<MyLoader/>} 
      </>
  )
}





{/* <Map
center={ll}
zoom={[16]}

style="mapbox://styles/mapbox/streets-v8"
className="w-full h-full rounded-lg hover:shadow"

>
<Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
  <Feature coordinates={ll} />
</Layer>  

<Marker
coordinates={ll}
anchor="bottom">
   <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-green-400 opacity-75"></span>
   <span class="relative inline-flex rounded-full h-10 w-10 bg-green-500"></span>
</Marker>


</Map> */}