import React, {useEffect,useMemo,useState,useRef,memo} from 'react'
import { Dialog, Transition ,Switch} from '@headlessui/react'
import { BrowserRouter, Route, Link ,Redirect,useHistory} from "react-router-dom";
import { axios ,productStats} from '../Network'
import DatePicker from "react-datepicker";
// import moment from 'moment'
import moment from 'moment-timezone'
import CartDetails from './Cart'
import CustomCalendar from '../component/CustomCalendar'
import { Fragment } from 'react'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { compareAsc, format,setHours ,setMinutes} from 'date-fns'
import ReactGA from 'react-ga';
import ContentLoader from 'react-content-loader'
var primaryProduct = process.env.REACT_APP_DEFAULT_PRIMARY_PRODUCT
var secondProduct = process.env.REACT_APP_DEFAULT_SECOND_PRODUCT

const AvatarIcon = props => (
  <ContentLoader viewBox="0 0 400 160" height={100} width={400} >
    <circle cx="48" cy="48" r="48" />
  </ContentLoader>
)
ReactGA.initialize('G-VFGQGCF3H0', {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function FixedDatePicker(props) {
    const [show, setShow] = useState(true)
    let [isOpen, setIsOpen] = useState(false)
    let [currentView, setCurrentView] = useState(1)
    const [userDate, setDate] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState({displayName:primaryProduct ,title:primaryProduct})
    const [enabled, setEnabled] = useState(false)
    const [isLoading, setLoading] = useState(props.isLoading)
    const [startDate, setStartDate] = useState(moment(setHours(setMinutes(new Date(), 0), 9)).add(1, 'days').tz("Asia/Manila").toDate());
    const handleChange = (e) => {
       localStorage.removeItem('UserCart')
        setIsOpen(!isOpen);
        setStartDate(moment(e).tz("Asia/Manila").tz("Asia/Manila").toDate());
        props.validate()
      ReactGA.outboundLink(
        {
          label: `User calendar selected Date ${moment(e).tz("Asia/Manila").tz("Asia/Manila").toDate()}frame`
        },
        function () {
          console.log('SSSS');
        },
        ['tracker2']
      );

      };

      useEffect(async () => {   
        let userSeletedDate = localStorage.getItem('userQ')
        var date = JSON.parse(userSeletedDate)
        if (date != null) {
          if (moment(date.date).tz("Asia/Manila").add(1, 'days').tz("Asia/Manila").toDate() <= moment(setHours(setMinutes(new Date(), 0), 1)).add(1, 'days').tz("Asia/Manila").toDate()) {
            setStartDate(moment(setHours(setMinutes(new Date(), 0), 9)).add(1, 'days').tz("Asia/Manila").toDate())
          }else {
            setStartDate(moment(date.date).tz("Asia/Manila").add(1, 'days').tz("Asia/Manila").toDate())
          }
        }else{
          setStartDate(moment(setHours(setMinutes(new Date(), 0), 9)).add(1, 'days').tz("Asia/Manila").toDate())
        }
      
      }, [])
    
      const handleClick = (e) => {
 
        setIsOpen(!isOpen);
      };

      const newDate = () =>{
        var new_date = moment(new Date());  
        return  new_date.add(1, 'days');
      }

    const displayCalendar = () => {
        return (
          // hidden sm:block
          <div className="">
            <button className="example-custom-input border border-gray-200 hover:border-black  align-middle  rounded-lg " style={{marginBottom:isOpen ? 20: 0}} onClick={handleClick}>
            <div className="m-2  "  > 
             <span className="hidden sm:block  md:block "> 
             {isOpen ?  `Date: ${moment(startDate).tz("Asia/Manila").format("MMMM DD YYYY , h:mm A")}` : `${enabled  ? '': '' } Date: ${moment(startDate).tz("Asia/Manila").format("MMMM DD YYYY, h:mm A")}`}
            </span>
            <span className=" block sm:hidden ">
              {/* Select Date */}
              <img src="https://img.icons8.com/cotton/344/calendar--v2.png" height={20} width={20} />
            </span>
            </div>
            </button>
            {isOpen && (
              <DatePicker 
              inline={true}
              minDate={moment(newDate()).tz("Asia/Manila").toDate()}
              timeIntervals={60}
              showTimeSelect
              excludeDates={[moment('2022-05-06').toDate(),moment('2022-05-07').toDate(),moment('2022-05-08').toDate()]}
              excludeTimes={[
                setHours(setMinutes(new Date(), 0), 0),
                setHours(setMinutes(new Date(), 0), 1),
                setHours(setMinutes(new Date(), 0), 2),
                setHours(setMinutes(new Date(), 0), 3),
                setHours(setMinutes(new Date(), 0), 4),
                setHours(setMinutes(new Date(), 0), 5),
                setHours(setMinutes(new Date(), 0), 6),
                setHours(setMinutes(new Date(), 0), 7),
                setHours(setMinutes(new Date(), 0), 18),
                setHours(setMinutes(new Date(), 0), 19),
                setHours(setMinutes(new Date(), 0), 20),
                setHours(setMinutes(new Date(), 0), 21),
                setHours(setMinutes(new Date(), 0), 22),
                setHours(setMinutes(new Date(), 0), 23),
              ]}
              onKeyDown={(e) => console.log('EEEE',e)}
              showTimeSelectOnly={false}
              selected={startDate} onChange={handleChange} inline />
            )}
          </div>
        );
        
      };
      const displaySwitcher = () =>{
        return (
          <div className="text-center  flex   ">
          <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? 'bg-blue-600' : 'bg-gray-200'
        } relative inline-flex items-center h-6 rounded-full w-11`}
      >
        {/* <span className="sr-only text-black">Order Type</span> */}
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>
      <span className="ml-2">{enabled ? ' For Delivery ': ' For Pickup ' }</span>
      </div>
        )
      }
      const saveState = (startDate,title) =>{

        var convertedTime = moment(startDate).tz("Asia/Manila").toDate()
        var searchDetails = {
          date:convertedTime,
          type:title
        }
        localStorage.setItem('userQ', JSON.stringify(searchDetails));
        props.tapped(startDate,title)
      }
      const searchButton = () => { 
        return (  <>
          <button
          onClick={()=>saveState(startDate,selectedCategory.title)}
          disabled={1  !== 0 ? false : true}
          type="button"
          className= {1  !== 0 ?  "my-2 inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white border border-transparent rounded-full hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  :"focus:ring-indigo-500 inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-gray-200 border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  }
          style={{backgroundColor:displayColorBG().buttonColor}}
        >  
        {props.isLoading ? <> <div className="hidden sm:block"><span className="font-sm " >Fetching...</span></div>
         <div className="">
         <svg version="1.1" id="loader-1" className="animate-spin h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" space="preserve">
         <path opacity="0.2" fill="#FFFF" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
           s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
           c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
         <path fill="#FFFF" className="border-white border-2" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
           C22.32,8.481,24.301,9.057,26.013,10.047z">
           </path>
         </svg>
       </div></>: <><div className="hidden sm:block"><span className="font-sm " >Search</span></div>
        <a href="javascript:void(0);" >
        <svg xmlns="http://www.w3.org/2000/svg" className="lg:ml-2 md:ml-2 h-5 w-5" fill="none" viewBox="0 0 20 20" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
</svg>
          {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg> */}
          </a> </>}
        </button></>)
      }

      const displayColorBG =()=>{
        try {
          return props.storeDetails.storeOptions.colorTheme
        }catch{
          
        return 'white'
        }
      }
    const displayContent =()=> {
      console.log('props.storeDetails',props)
   try {
       // return    <div hidden className=" shadow-xl  flex items-center  justify-center px-40 py-30 font-medium full rounded-mid  bg-white md:py-30 md:text-lg md:px-10 md:bg-white xs:bg-yellow-400 lg:bg-red-500 xl:bg-yellow-100" style={{ float: 'bottom',width:'100%', position: 'fixed', position: 'fixed',  top: 0, zIndex: 20,height:isOpen ? '99%':'auto' ,
      // px-20 
      return    <div  className=" shadow-xl   flex items-center   transition-all   justify-between sm:px-0 sm:px-30 font-medium full rounded-mid  bg-white md:py-30 md:text-lg md:px-10 md:bg-white xs:bg-yellow-400 lg:bg-red-500 xl:bg-black" style={{ 
        position: 'fixed',
        width:'100%',  zIndex: 20, 
        backgroundColor:isOpen === false ? displayColorBG().componentColor : 'white',
        // borderWidth:1,
        // borderColor:'#ffcccc'
      }}>
       <div  className= {isOpen ? "bg-white h-100 w-100  " :"   h-200 w-200 rounded hidden sm:block md:block " }>
  {props.storeDetails === null ? <AvatarIcon/> :<img class={isOpen ? "hidden" :"lg:w-12 lg:h-12 m-2 hover:shadow-lg md:w-full md:h:full rounded hidden md:block  " } src={props.storeDetails === null ? 'https://localflowershop.sgp1.digitaloceanspaces.com/product/1645782083756-nidz-logo-22.jpg': props.storeDetails.storeLogo}/>} 
       
       </div>
       <div className=" w-full sm:w-full md:w-full lg:w-auto m-5  bg-white rounded-full ">
      <div className="text-center   ">
      <div className="text-sm text-gray-900 rounded-lg flex items-center " style={{ margin:5,marginLeft:20,borderWidth:0,borderColor:'black',color:'black'}}> 
      {isOpen ? null : displayDropdown()}
        {startDate === null ? ' Select your Preferred Date' : isOpen ? null: ''}  {displayCalendar()}
        {isOpen ? `${enabled  ? ' ': ' ' } ` : searchButton()}
      </div>
      </div>
      </div> 
      <div className="h-10 w-10 hidden sm:block ">
      </div>
    </div>
   } catch (error) {
    console.log('error fix date picker',error)
    return <span></span>
   }
    }

    const loadCategory =(e)=>{
      var contents = []
      var data = []
      e.map(item => {
        if (item.status) {
          data.push(
            // <CategoryView onClick={(e) => reload(e)} active={category} data={item} />
            <Menu.Item>
                  {({ active }) => (
                     <div className="py-1 uppercase">
                    <a
                      href="javascript:void(0);."
                      onClick={()=>setSelectedCategory(item)}
                      className={classNames(
                        selectedCategory.displayName === item.displayName ? 'bg-gray-100 text-black font-bold uppercase' : 'text-gray-700 font-light uppercase',
                        'block px-4 py-2 text-sm uppercase'
                      )}
                    >
                      {item.displayName}
                    </a>
                    </div>
                  )}
                </Menu.Item>
          )
        }
      })
      return data
    }

    const displayDropdown=()=>{
      return (
        <Menu as="div" className="relative inline-block text-left mr-4 ">
            {({ open }) => (
              <>
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <span className="hidden sm:block md:block uppercase">{selectedCategory === null ? 'Flower Category' : selectedCategory.displayName}</span>
              <span className=" block sm:hidden md:hidden ">
              Category...
            </span>
              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          {/* {open ?"px-20" : "py-1"} */}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-50"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-10"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-100  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            {({ active }) => (
              <div 
              // className={`${active && 'text-blue-500'}`}
              className="py-1"
              >
                {loadCategory(props.categories,active)}
              </div>
              )}
            </Menu.Items>
          </Transition>
          </>)}
        </Menu>
      )
    }


    return <div className="bg-black w-full h-3" >{displayContent()}</div>
}