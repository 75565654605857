import * as React from 'react';  
import Axios from 'axios'; 

export const url =  "https://www.smestoreph.com" //"http://192.168.1.148:9091" // process.env.REACT_APP_STORE_STORE_ENDPOINT // //"https://store-api-ddv8.onrender.com" //      //"https://www.smestoreph.com" //
export const urlFirebase = process.env.REACT_APP_STORE_STATISTIC
export const urlSupBase = "https://www.smestoreph.com"  // "http://192.168.1.148:3001" // 
export const axios = Axios.create({
    baseURL:url,
    timeOut:3000,
    headers: {
        "Content-Type": "application/json"
    }
});
export const emailSender = Axios.create({
    baseURL:"https://emailsentph.com/api",
    timeOut:3000,
    headers: {
        "Content-Type": "application/json"
    }
});
export const productStats = Axios.create({
    baseURL:urlFirebase,
    timeOut:3000,
    headers: {
        "Content-Type": "application/json"
    }
});

export const productLike = Axios.create({
    baseURL:urlSupBase,
    timeOut:3000,
    headers: {
        "Content-Type": "application/json"
    }
}); 