import React from 'react';
import Store from './Store';
import CD from './component/CustomerDetails';
import Detaiils from './productDetails';
import CMS from './CMS';
import Test from './Test';
import Terms from './TermsCo';
import Privacy from './Privacy';
import ContactUs from './ContactUs';
import Replace from './replace';
import Aboutus from './abouts';
import RefundPolicy from './RefundPolicy';
import SearchStore from './Store';
import { BrowserRouter, Route, Link,Switch } from "react-router-dom"; 
// import Test from './Te';

const RouterManager = () => (   
  <BrowserRouter>
    <Switch>   
    
      <Route exact path="/Cart" component={CD}/> 
    
      <Route exact path="/PrivacyPolicy" component={Privacy}/> 
      <Route exact path="/TermsAndCondition" component={Terms}/> 
      <Route exact path="/Contact" component={ContactUs}/> 
      <Route exact path="/replace" component={Replace}/> 
      <Route  path="/About" component={Aboutus}/> 
      <Route  path="/RefundPolicy" component={RefundPolicy}/> 
      <Route exact path="/:product?" component={SearchStore}/> 
      <Route exact path="/Details/:productName?" component={Detaiils}/> 
      
    </Switch> 
</BrowserRouter> 
)


export default RouterManager;
