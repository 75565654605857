import { Fragment, useState ,useEffect} from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { Switch,Popover } from '@headlessui/react'
import { BeakerIcon ,ShoppingCartIcon} from '@heroicons/react/solid'
import Footer from './component/Footer'
import LandingHeader from './component/LandingHeader'
import { axios } from './Network'
import toast, { Toaster } from 'react-hot-toast';
import ContentLoader from "react-content-loader"
var id =   process.env.REACT_APP_STORE_ID

const MyLoader = (props) => (
  <ContentLoader viewBox="0 0 400 31" height={200} width={'auto'} {...props}>
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="5" />
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="15" />
        <circle cx="388" cy="12" r="12" />
        <rect height="24" rx="0" ry="0" width="24" x="0" y="0" />
      </ContentLoader>
  )
export default function Example(props) {
  const [open, setOpen] = useState(true) 
  let [isOpen, setIsOpen] = useState(true)
  const [enabled, setEnabled] = useState(false)
  var product = props.item
  let [didLoad, didLoadStore] = useState(false)
  let [store, setStoreDetails] = useState(null)

  useEffect(() => {
    let parameter = {
      id: id,
      equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
    }
    axios
    .post("/details/Store", parameter)
    .then((response) => {
      setStoreDetails(response.data.results[0])
      didLoadStore(true)
      
    })
    .catch(function (error) {
      toast('Something went wrong, please try again')
    });
  }, [])
  return (
      <>
      
 <div class="m-10"> 
 <>
    <h1 className="text-lg font-bold text-slate-700 mb-10">About Us</h1>
    <h1 className="text-sm font-bold text-slate-700">Our Story</h1> 
<p>Welcome to our flower shop, a trusted and reputable florist in Iligan City for over 15 years. We are proud to say that we have been delivering high-quality and exquisite floral arrangements since our establishment in 2004 at Pala-o Market with only two staff.
<div className='mt-10'/>
</p>
<p>Over the years, we have grown and expanded our services, catering to individual customers, big corporations, and government events. Our team of experienced florists has mastered the art of floral design, providing the best possible service to our customers.</p>
<div className='mt-10'/>
<p>We take pride in creating personalized floral arrangements that are tailored to your needs, whether it's a bouquet for your loved one, a centerpiece for your wedding, or a grand floral arrangement for a corporate event. Our goal is to bring your vision to life and exceed your expectations with our stunning floral designs.</p>
<div className='mt-10'/>
We source our flowers from trusted local and international suppliers, ensuring that our customers receive only the freshest and highest quality blooms. Our commitment to quality and customer satisfaction has earned us a loyal following over the years.
<div className='mt-10'/>
At our flower shop, we believe that every occasion deserves the perfect floral arrangement. Our team is dedicated to providing exceptional service, and we go above and beyond to make sure that every customer is satisfied with our work.

Thank you for choosing us as your go-to florist in Iligan City. We look forward to serving you for many more years to come.
    </>

    <section>
  <div class="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
    {/* <header class="text-center">
      <h2 class="text-xl font-bold text-gray-900 sm:text-3xl">
        New Collection
      </h2>

      <p class="max-w-md mx-auto mt-4 text-gray-500">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Itaque
        praesentium cumque iure dicta incidunt est ipsam, officia dolor fugit
        natus?
      </p>
    </header> */}

    <ul class="grid grid-cols-1 gap-4 mt-8 lg:grid-cols-3">
      <li>
        <a href="/" class="relative block group">
          <img
            src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1678895666961-309929403_519932946806916_4682159831488024056_n.jpg"
            alt=""
            class="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            class="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
            <h3 class="text-xl font-medium text-white">Corporations</h3>

            <span
              class="mt-1.5 inline-block bg-black px-5 py-3 text-xs rounded-full font-medium uppercase tracking-wide text-white"
            >
              Shop Now
            </span>
          </div>
        </a>
      </li>

      <li>
        <a href="/" class="relative block group">
          <img
            src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1678895620569-329374368_585819756384095_4700813843047961952_n.jpg"
            alt=""
            class="object-cover w-full  rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            class="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
            <h3 class="text-xl font-medium text-white">SME's</h3>

            <span
              class="mt-1.5 inline-block bg-black px-5 py-3 text-xs rounded-full font-medium uppercase tracking-wide text-white"
            >
              Shop Now
            </span>
          </div>
        </a>
      </li>

      <li class="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
        <a href="/" class="relative block group">
          <img
            src="https://localflowershop.sgp1.digitaloceanspaces.com/product/1678895558917-282211211_1893462097514057_8466299064680741685_n.jpg"
            alt=""
            class="object-cover w-full rounded-lg transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            class="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
            <h3 class="text-xl font-medium text-white">Governments</h3>

            <span
              class="mt-1.5 inline-block bg-black px-5 py-3 text-xs rounded-full font-medium uppercase tracking-wide text-white"
            >
              Shop Now
            </span>
          </div>
        </a>
      </li>
      
    </ul>
  </div>
</section>


    </div>

    {store !== null ? <Footer  details={store} />:<MyLoader/>} 
      </>
  )
}