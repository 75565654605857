import { useState } from 'react'
import { Switch } from '@headlessui/react'
import {productStats} from '../Network'

export default function Example(props) {
  const [enabled, setEnabled] = useState(false)

  async function recordProductStats(e,type) {
    try {
        const data = {storeOwner:"PaculbaFlowerShop",cType:"cartBehavior",cName:"website","data":e,"date":new Date(),action:type}
        const response = await productStats.put('/items', data) 
        return  response
        
    } catch (error) {
    }
  }

  function removeItemService(data){
    props.removeItem(data)
    recordProductStats(data,"Removed")

  }
  function renderItem() {
    var product = props.items
    var item = [<></>]
    product.map(data => {
      item.push(
        <div key={data._id} className="mt-2  h-auto hover:z-50 inline-block w-full max-w-md p-6 my-3 overflow-hidden text-left align-right transition-all transform bg-white shadow-sm hover:shadow-xl rounded-2xl">
          <div className="transform grid grid-flow-col  grid-cols-3">
            <img
              className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
              src={data.imgUrl}
              alt=""
            />
     

            <div class="hidden lg:block">
            <p className= {props.showDelete ? " text-sm  text-gray-900" :"text-sm  text-gray-400"}>
              {data.title.substring(0, 60)}
            </p>
</div>
            <p className={props.showDelete ?  "text-sm font-bold ml-8 text-gray-700" :  "text-sm font-bold ml-8 text-gray-400" } >
              {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(data.price)}
            </p>
          </div>
          {props.showDelete ? <div className="">
            <a href="javascript:void(0);" className="absolute" style={{ position: 'fixed', position: 'fixed', right: 3, position: 'fixed', top: 0, zIndex: 1 }} onClick={() => removeItemService(data)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg></a>
          </div> : null }
          
        </div>
      )
    })
    return item
  }

  return  renderItem()
}
