import React from 'react';

function Mega(props) {
    return (
        <div>
  
            
        </div>
    );
}

export default Mega;