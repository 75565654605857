import { Fragment, useState, useEffect ,useMemo,useCallback} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BrowserRouter, Route, Link,Switch ,Redirect,useHistory, useLocation ,useSearchParams} from "react-router-dom";
import { axios ,productStats} from '../Network'
import CartDetails from './Cart'


// import moment from 'moment'
import moment from 'moment-timezone'
import { compareAsc, format,setHours ,setMinutes} from 'date-fns'
export default function Example(props) {
 const location = useLocation()
  const history = useHistory()

  const [show, setShow] = useState(false)
  let [isOpen, setIsOpen] = useState(true)
  let [currentView, setCurrentView] = useState(1)
  const [userDate, setDate] = useState(null)
  const [currentProduct, setCurrentProduct] = useState(undefined)
  const [currentPath, setCurrentPath] = useState(undefined)
  const queryParams = new URLSearchParams(location)
   
  function closeModal() {
    setIsOpen(false)
  }

  useEffect(()=>{
    getProduct()
    
  },[currentPath])
  var  available = 'object-cover rounded-lg lg:object-cover md:object-cover  w-full -mt-3 h-80  lg:h-96 opacity-100 hover:opacity-50 ease-in-out duration-300'
  var  imageIcon = 'object-cover rounded-lg lg:object-cover md:object-cover  w-full -mt-3 h-96 opacity-100 hover:opacity-50 ease-in-out duration-300'
  useEffect(() => {
    setCurrentPath(decodeURI(window.location.pathname.substring(1)))
  
    let userSeletedDate = localStorage.getItem('userQ')
    var date = JSON.parse(userSeletedDate)
    if (date != null) {
     
      setDate(moment(date.date).tz("Asia/Taipei").toDate())
    }else{
      setDate( moment(setHours(setMinutes(new Date(), 0), 9)).tz("Asia/Taipei"))
    }
  }, [])
  const newDate = () =>{
    var new_date = moment(new Date()).tz("Asia/Taipei");  
    return  new_date.add(1, 'days');
  }

  const validateItem = (e)=>{
    console.log("myProduct",e)
    if (e === undefined) {
      return
    }
    try {
        let existingCart = localStorage.getItem('UserCart')

        var cart = JSON.parse(existingCart) 

        console.log("Existing",existingCart)
        const found = cart.find(obj => {
          console.log("items",obj)
            return obj._id === e._id;
          });
          
          let status =  found === undefined ? false :true
          return status
    } catch (error) {
      console.log('ERROR validate',error)
        return  false
    }
}


 async  function getProduct(){

try {
  
  var productTitle = decodeURI(window.location.pathname.substring(1))
  if (productTitle != "" ) {
    var parameter =  {isAPI: true,
      queryData: {title: decodeURI(window.location.pathname.substring(1))},
      queryType:  "custom" }
          axios
          .post("/store/Product", parameter)
          .then((response) => { 
           setCurrentProduct(response.data.results[0]) 
          })
          .catch(function (error) {
            console.log('fetching product', error)
          });
  }
   
} catch (error) {
    return null
}

  }
  function openModal() {
    setIsOpen(true)
  }
  useEffect(() => {
    var productTitle =  window.location.pathname.substring(1)
    
    console.log('query product',productTitle)
  }, [])

  const displayColorBG =()=>{
    try {
      return props.storeDetails.storeOptions.colorTheme
    }catch{
      
    return 'white'
    }
  }

  function manageDrawer() {
    props.showDrawer(!show)
    setShow(!show)
    
  }

  const people = [
    {
      id: 1,
      unavailable: true,
      name: 'Michael'
    }

  ]

  function removeItem(e) {
    props.removeItem(e)
  }

async   function recoredAddCalendar() {
    try {
        const data = {storeOwner:"Nidz",cType:"calendar",cName:"web","data":props.items,"date":new Date()}
        const response = await productStats.put('/Items', data) 
        alert('Not ready')
        return  response
    } catch (error) {
      
    }
    
  }
  const didAddItem = ()=>{
    
    if (validateItem(currentProduct) === false) {
      props.didAdd(currentProduct)
      setIsOpen(false)
      history.push('/')
    }else {
      setIsOpen(false)
      history.push('/')
    }
  }
  const  validateAddons = useMemo(()=> {
    var required = 1
    let allowed =  props.items.filter((item: { type: string; }) =>   item.type != "Food" && item.type != "Tart-Addons" && item.type != "Gifts" )
    return  allowed.length  >= required ? true : false
  },[props.items])
function redirectCheckout(){
  let path = `Cart`;
  props.history.push(path);
}
  function cart(){
    return ( 
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
             
          <div className="min-h-screen px-4 text-center bg-black bg-opacity-60"> 
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"> 
              <img
              onClick={()=>setIsOpen(false)}
              width={30}
              style={{position:'absolute',top:20,right:20}}
              height={30}
                className="hover:opacity-20 ease-in-out duration-300 mr-4"
              
              src={'https://cdn.iconscout.com/icon/free/png-256/x-circle-3602289-3005823.png'}
            />
            {/* <span>{currentProduct.type}</span> */}
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                 {currentProduct.title}
                </Dialog.Title>
                <div className=" justify-center align-middle items-center place-items-center">
                <h3 className="mt-2 mb-5 text-black text-xs   flex leading-5">
              {/* Date and time selected: <span className="text-blue-400 ml-2"> {moment(userDate).tz("Asia/Taipei").tz("Asia/Taipei").format("MMMM Do YYYY  h:mm A").toString()}</span><img src="https://img.icons8.com/material-outlined/344/calendar--v1.png" className="h-4 w-4 mx-2 " /> */}
              </h3>
              </div>
                <div className="mt-5 bar overflow-auto" style={{height: props.items.length <= 3 ? 'auto' :400,overflow:'auto'}}>
                <div>
       <a href="javascript:void(0);" class="block group">
    {true? null: <div class="flex justify-center">
          <strong class="relative rounded-full h-6 px-4 text-xs font-extralight leading-6 text-white uppercase bg-red-900"> Out of stock </strong>
        </div> } 
        
      <section className={available}>
  <img
          alt={"product.title"}
          src={ currentProduct.forAndroid === undefined ? currentProduct.imgUrl:  currentProduct.forAndroid}
           className={imageIcon}
          style={{
            marginBottom:20,
            opacity: 1
          }}
        />
      </section>
      {/* <h5 class="mt-4 text-sm  text-black/90"> {"TEST"} </h5> */}
            {/* <div class="flex items-center justify-between mt-4 font-bold">
              <p class="text-lg group-hover:text-black ease-in-out duration-300">
              { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(currentProduct.price)}
              </p>
              <div className='flex  grid-cols-2'>
              {true?  <img
              width={20}
              height={20}
                className="hover:opacity-20 ease-in-out duration-300 mr-4"
              alt={"product.title"}
              src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRymu6sl4j8YwAhAo8Rz0Owww4-jgw4gmmgQahRi5tyya1xVxAglgaUxpBXlBiEMRNFQM&usqp=CAU'}
            /> : null}
              </div>
            </div> */}
      </a>
        </div>
        
                  {/* <CartDetails items={props.items} removeItem={(e)=>removeItem(e)} showDelete={true}/> */}
                </div>

                <div className="mt-4">

                  {/* <button

                    type="button"
                    className="inline-flex  justify-center px-4 py-2 text-sm font-medium   text-black bg-gray-100 border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => recoredAddCalendar()}
                  > Add to Calendar
                  <a href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg></a>
                  </button> */}
{validateAddons ? null :<div
className="mb-4"
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
            <p class="text-red-500 text-xs ">  FLOWERS REQUIRED</p> 
                     <p category="c1"style={{marginTop:10}}>Sorry, you can't purchase without flowers.</p>
                    </div> }

                  <button
                    type="button"
                    disabled={validateAddons ? false :true}
                    className={`inline-flex w-full  justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-black-500 ` }

                    style={{backgroundColor:validateAddons ? displayColorBG().buttonColor : 'gray'}}
                    onClick={()=>didAddItem()}
                    
                  >
             Add to cart    { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(currentProduct.price)}
                  <a href={validateAddons ? "/Cart" : "javascript:void(0);"}  ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg></a>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
        </Transition>
    
    )
  }
  function customerDetails(){
    return (
      <Transition appear show={isOpen} as={Fragment}>
       
      <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={closeModal}
    > 
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Shey 
                </Dialog.Title>
                </div> 
      </Dialog>
      </Transition>
    )
  }
  function detectCartView(e){
    
    switch (e) {

      case 1:
        return cart()
        break;
      case 2:
        return customerDetails()
      default:
        return <>Empty</>
        break;
    }
  }
  return (
    <div >
  { currentProduct === undefined ? null : cart() }
   

    </div>



  )

}