import { Fragment, useState ,useEffect} from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { Switch,Popover } from '@headlessui/react'
import { BeakerIcon ,ShoppingCartIcon} from '@heroicons/react/solid'
import Footer from './component/Footer'
import LandingHeader from './component/LandingHeader'
import { axios } from './Network'
import toast, { Toaster } from 'react-hot-toast';
import ContentLoader from "react-content-loader"
var id =   process.env.REACT_APP_STORE_ID

const MyLoader = (props) => (
  <ContentLoader viewBox="0 0 400 31" height={200} width={'auto'} {...props}>
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="5" />
        <rect height="5.5" rx="1" ry="1" width="340" x="31" y="15" />
        <circle cx="388" cy="12" r="12" />
        <rect height="24" rx="0" ry="0" width="24" x="0" y="0" />
      </ContentLoader>
  )
export default function Example(props) {
  const [open, setOpen] = useState(true) 
  let [isOpen, setIsOpen] = useState(true)
  const [enabled, setEnabled] = useState(false)
  var product = props.item
  let [didLoad, didLoadStore] = useState(false)
  let [store, setStoreDetails] = useState(null)

  useEffect(() => {
    let parameter = {
      id: id,
      equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
    }
    axios
    .post("/details/Store", parameter)
    .then((response) => {
      setStoreDetails(response.data.results[0])
      didLoadStore(true)
      
    })
    .catch(function (error) {
      toast('Something went wrong, please try again')
    });
  }, [])
  return (
      <>
      
 <div class="m-10"> 
 <title>Paculba Flowershop - Refund Policy</title>
 <>
    <h1 className="text-lg font-bold text-slate-700 mb-10">Refund Policy for Paculba FlowerShop</h1>
    <h1 className="text-sm font-bold text-slate-700">Effective Date {'[January 1, 2023]'}</h1> 
   <p>Thank you for choosing Paculba Flower shop for your floral designs needs. We strive to provide you with the best products/services and exceptional customer service. However, we understand that there may be situations where you may require a refund. Please read our refund policy carefully to understand your rights and responsibilities.</p>
  
 {/* <p class="font-sans ...">These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions. These Terms and Conditions have been generated with the help of the <a href="https://www.termsandcondiitionssample.com">Terms And Conditiions Sample Generator</a>.</p> */}
  
   {/* <p class="font-sans ...">Minors or people below 18 years old are not allowed to use this Website.</p> */}
  
   <h1 className="text-sm font-bold text-slate-700 mb-5 mt-10">1. Refund Eligibility</h1>
  
   <p class="font-sans ...">We offer refunds under the following circumstances:</p>
  
   <p>a. Product/Service Defect:  </p>
   <p className='ml-4'>If you receive a defective or damaged product or if our service does not meet the agreed-upon specifications, we will enhance the service and do better more. </p>
  
   <h1 className="text-sm font-bold text-slate-700 mb-5 mt-10">Cancellation</h1>
  
   <p>If you cancel your order within 3 days of purchase and before the product or service has been delivered or provided, you are eligible for a refund.</p>
  
   {/* <ul>
     <li>publishing any Website material in any other media;</li>
     <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
     <li>publicly performing and/or showing any Website material;</li>
     <li>using this Website in any way that is or may be damaging to this Website;</li>
     <li>using this Website in any way that impacts user access to this Website;</li>
     <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
     <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
     <li>using this Website to engage in any advertising or marketing.</li>
   </ul> */}
  
   <p>Certain areas of this Website are restricted from being access by you and Paculba FlowerShop may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</p>
  
   <h1 className="text-sm font-bold text-slate-700 mb-5 mt-10">Dissatisfaction</h1>
  
   <p> If you are not satisfied with our product or service and wish to request a refund, please contact our customer support team within a day of purchase. Refund eligibility in such cases will be determined on a case-by-case basis.
</p>
  
   {/* <p>Your Content must be your own and must not be invading any third-party’s rights. Paculba FlowerShop reserves the right to remove any of Your Content from this Website at any time without notice.</p> */}
  
   <h1 className="text-sm font-bold text-slate-700 mb-5 mt-10" >2. Refund Process</h1>
  
   <p>To request a refund, please follow these steps:</p>
  
  <p>a. Contact Customer Support</p>
  <p>Reach out to our customer support team by 0975-365-3640, providing your order number and a detailed explanation of the reason for your refund request.</p>


  <p>b. Inspection and Verification</p>
  <p>In the case of product defects or damages, we may request photographic evidence to validate your claim. For services, we may review the circumstances and outcomes.</p>

  

  <p>c. Refund Approval:</p>
  <p>Once your refund request is approved, we will notify you and process the refund within 24 hours. The refund will be issued through the original payment method.</p>




   <h1 className="text-sm font-bold text-slate-700 mb-5 mt-10">Contact Information</h1>
  
   <p>If you have any questions or concerns regarding our refund policy, please contact us: 0975-365-3640
</p>
  
   <h1 className="text-sm font-bold text-slate-700 mb-5 mt-10">Changes to This Policy</h1>
  
   <p>We reserve the right to update or modify this refund policy at any time. Any changes will be effective immediately upon posting on our website. It is your responsibility to review this policy periodically for updates.</p>
   <p className='mt-10'>By making a purchase with Paculba Flowershop, you agree to and accept the terms of this refund policy.</p>
      </>

    </div>

    {store !== null ? <Footer  details={store} />:<MyLoader/>} 
      </>
  )
}